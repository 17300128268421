/* stylelint-disable value-keyword-case */

/* 2024-09-10 KY: Disabled above rule for this file. */

$font-stack: (
  "georgia": (
    Georgia,
    "URW Bookman L",
    serif,
  ),
  "helvetica": (
    Helvetica,
    Arial,
    "Nimbus Sans L",
    sans-serif,
  ),
  "lucida-grande": (
    "Lucida Grande",
    "Lucida Sans Unicode",
    "Bitstream Vera Sans",
    sans-serif,
  ),
  "monospace": (
    "Courier New",
    Courier,
    "Nimbus Sans L",
    monospace,
  ),
  "system": (
    -apple-system,
    system-ui,
    BlinkMacSystemFont,
    "Segoe UI",
    "Roboto",
    "Oxygen",
    "Ubuntu",
    "Cantarell",
    "Fira Sans",
    "Helvetica Neue",
    Helvetica,
    Arial,
    sans-serif,
  ),
  "verdana": (
    Verdana,
    Geneva,
    "DejaVu Sans",
    sans-serif,
  ),
);

/// Return a font stack list from a map. Equivalent to `map-safe-get($name, $font-stack)`.
///
/// @param {String} $stack - Name of the font stack.
/// @param {Map} $map [$font-stack] - Map of font stacks to retrieve a list from.
///
/// @returns {List} Found font stack.
@function font-stack($stack, $map: $font-stack) {
  @return map-safe-get($map, $stack);
}

/// Get font family or weight from $fonts
///
/// @param {key} color key from $fonts
/// @param {key} $weight-key - key from $fonts weight
///
/// @returns {String} color from $fonts
@function font($key, $weight-key: null) {
  @if map-has-key($fonts, $key) {
    $font-group: map-get($fonts, $key);

    @if type-of($font-group) == map {
      @if $weight-key {
        @if map-has-key($font-group, "weights") {
          $font-weights: map-get($font-group, "weights");
          @if map-has-key($font-weights, $weight-key) {
            @return map-get($font-weights, $weight-key);
          } @else {
            @error "given $key(#{$weight-key}) is not available in $fonts weights";
          }
        }
      }
      @if map-has-key($font-group, "family") {
        @return map-get($font-group, "family");
      } @else {
        @error "given $key(#{$key}) is not available in $fonts";
      }
    } @else {
      @return $font-group;
    }
  } @else {
    @error "given $key(#{$key}) is not available in $fonts";
  }
}
