@import "tools";

/**
* Settings
* NOTE: inherits default form field color variables
* from "tools"
*/

$sectionPadding: 24px;
$sectionBorderColor: $colorMercury;
$sectionTextColor: $colorAbbey;
$minorTextColor: $colorRollingStone;

/**
* Component Styles
*/

uc-section-header {
  display: block;
  padding-bottom: $sectionPadding;
  border-bottom: 1px solid $sectionBorderColor;
  margin-bottom: 30px;

  p {
    color: $sectionTextColor;
    font-size: uc-rem-convert(14);
    line-height: 1.35;
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.section-header-title {
  font-size: uc-rem-convert(20);
}

.subsection-question {
  margin-bottom: 10px;
}

uc-subsection-minor-text {
  display: block;
  font-size: uc-rem-convert(12);
  line-height: 1.5;
  color: $minorTextColor;
  margin-bottom: 15px;
  font-weight: 400;
}

uc-subsection {
  display: block;
  font-size: uc-rem-convert(16);
  padding-top: $sectionPadding;
  padding-bottom: $sectionPadding - 10px;
  border-bottom: 1px solid $sectionBorderColor;

  p {
    margin-bottom: 5px;
  }
}

uc-subsection-text-block {
  display: block;
  font-size: uc-rem-convert(14);
  line-height: 1.35;
  margin-bottom: 15px;
}

uc-subsection-text-block p {
  margin-bottom: 15px;

  &:last-of-type {
    margin-bottom: 0;
  }
}

uc-section {
  display: block;
}

.required-asterisk {
  color: $fieldRequiredAsteriskColor;
}

@media (min-width: 960px) {
  uc-section {
    border: 1px solid $sectionBorderColor;
    border-radius: 3px;
    box-shadow: 0 3px 2px 0 rgba(0, 0, 0, 0.1);
    margin-bottom: 15px;
  }

  uc-section-header,
  uc-subsection {
    padding-left: $sectionPadding;
    padding-right: $sectionPadding;
  }

  uc-section-header {
    padding-top: $sectionPadding;
    margin-bottom: 0;
  }
}
