/* eslint-disable @typescript-eslint/dot-notation */
import { Modal } from '@4sellers/ngx-modialog';
import { HttpHeaders, HttpResponse, HttpParams } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';
import { MsalService, MsalBroadcastService } from '@azure/msal-angular';
import {
  AccountInfo as B2CAccountInfo,
  AuthenticationResult,
  IPublicClientApplication,
  PublicClientApplication,
  InteractionStatus,
} from '@azure/msal-browser';
import * as Sentry from '@sentry/angular';
import { FacebookAuthProvider, GoogleAuthProvider } from 'firebase/auth';
import firebase from 'firebase/compat/app';
import jsCookie from 'js-cookie';
import { get } from 'lodash-es';
import { DateTime } from 'luxon';
import { throwError, BehaviorSubject, Observable, Subject, Subscription, from, timer, of } from 'rxjs';
import { map, switchMap, tap, catchError, filter, first } from 'rxjs/operators';

import { internalUrls } from '@constants/internalUrls';
import strings from '@constants/strings.constants';
import { environment } from '@environment';
import { AdminViewUser } from '@shared/models/admin-view-user';
import { Applicant } from '@shared/models/applicant';
import { UCError } from '@shared/models/errors';
import { NotificationTypes, Notification } from '@shared/models/notification';
import { User, UserAlias, UserDetail, UserSubTypes, UserTypes } from '@shared/models/user';
import { CacheManagementService } from '@shared/services/cache-management/cache-management.service';
import { DataService, DSHttpError, UCErrorCodes } from '@shared/services/data-service';
import { IPLocationService } from '@shared/services/iplocation/iplocation.service';
import { LoggingService, Logger } from '@shared/services/logging/logging.service';
import { ModalService } from '@shared/services/modal/modal.service';
import { NotificationService } from '@shared/services/notification/notification.service';
import { firebaseErrorMap } from '@shared/services/user/firebaseErrors';
import { WindowService } from '@shared/services/window/window.service';

export { MockUserService } from './user-mock.service';

const isEmailRegex = /@.+\..+$/;
const ucEmailRegex = /@uclive\.ac\.nz$/i;

export interface IUserService {
  currentUser: Observable<User>;
  isLoggedIn: Observable<boolean>;
  errors: Observable<UCError>;
  updateEmail(email: string): Promise<void>;
  loginUC(email: string, password: string): void;
  logout(): void;
  verifyEmail(): Promise<void>;
}

export interface UserFormData {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
}

@Injectable()
export class UserService implements IUserService {
  public canonicalIdCookieName = 'MyUCUserID';
  private serviceUrl = `${environment.apiRoot}/auth/`;
  log: Logger;
  strings = strings.components.template.emailVerification;

  private user$ = new BehaviorSubject<User>(null);
  private userDetail$ = new BehaviorSubject<UserDetail>(null);
  // userError$ needs to be public because we have error-fallback/control-flow happening on the login form
  public userError$ = new Subject<UCError>();
  public userSearchParams: { [key: string]: string } = {};
  private _loginUid: string;
  public isCreatingAccount = false;

  private newUser: User;

  private lastAuthTokenAt: Date;
  private firebaseUser: firebase.User;
  private lastTokenSubscription: Subscription;
  private loggedUserCheckSubscription: Subscription;
  public authSubscription: Subscription;
  private loggingOut = false;
  private outageNotification$ = new BehaviorSubject<Notification>(null);
  private loggedUserCheck$ = new BehaviorSubject<boolean>(null);

  public useFirebaseServer = false;
  public firebaseServerURL = `${environment.apiPrefix || ''}/firebase-api`;
  public cookieIdToClear$ = new BehaviorSubject<string>(null);
  public isFirebaseEmailLogin$ = new BehaviorSubject<boolean>(null);
  public isB2CLogin$ = new BehaviorSubject<boolean>(null);

  public MSAL_INSTANCE: IPublicClientApplication;

  azureB2CRedirectSubscription: Subscription;

  // eslint-disable-next-line max-lines-per-function
  constructor(
    public af: AngularFireAuth,
    private dataService: DataService,
    loggingService: LoggingService,
    private modal: Modal,
    private modalService: ModalService,
    private ws: WindowService,
    private router: Router,
    private zone: NgZone,
    private cacheService: CacheManagementService,
    private notificationService: NotificationService,
    private ipLocationService: IPLocationService,
    public msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
  ) {
    this.log = loggingService.createLogger(this);

    this.subscribeToAuthEvents();
    this.outageListener();
    this.getIPLocation();
    this.actionsForError();
    this.sentryUserSetup();
    this.msalInstanceForB2C();
  }

  public get loginUid() {
    return this._loginUid;
  }

  get errors(): Observable<UCError> {
    return this.userError$.asObservable();
  }

  get cookieIdToClear(): Observable<string> {
    return this.cookieIdToClear$.asObservable();
  }

  public get hasTokenSubscription(): boolean {
    return !!this.lastTokenSubscription;
  }

  public get isFirebaseEmailLogin(): boolean {
    return this.isFirebaseEmailLogin$.value;
  }

  public get isB2CLogin(): boolean {
    return this.isB2CLogin$.value;
  }

  get validationError(): Observable<UCError> {
    return this.userError$.asObservable();
  }

  get currentUser(): Observable<User> {
    return this.user$.asObservable();
  }

  get user(): User {
    return this.user$.value;
  }

  get isLoggedIn(): Observable<boolean> {
    return this.user$.asObservable().pipe(map((user) => !!user));
  }

  get userDetail(): Observable<UserDetail> {
    return this.userDetail$.asObservable();
  }

  get userDetailValue(): UserDetail {
    return this.userDetail$.value;
  }

  get userClaims(): string[] {
    return get(this.user, 'claims') || null;
  }

  get isInOutage(): Observable<boolean> {
    return this.notificationService.notifications.pipe(
      map((notifications: Notification[] | null) => {
        if (!Array.isArray(notifications)) {
          return notifications;
        }

        return notifications.filter((not) => {
          const notificationInPast = not.expiry < new Date();
          const timeDiff = DateTime.now().diff(DateTime.fromJSDate(not.expiry), 'minutes').minutes;
          return not.type === 'outage' && notificationInPast && timeDiff < 10;
        });
      }),
      map((notifications) => !!notifications?.length),
    );
  }

  get outageNotification(): Observable<Notification> {
    return this.outageNotification$.asObservable();
  }

  get loggedUserCheck(): Observable<boolean> {
    return this.loggedUserCheck$.asObservable();
  }

  public static hasB2CConfig() {
    return 'azureB2C' in environment;
  }

  private msalInstanceForB2C() {
    if (UserService.hasB2CConfig()) {
      this.MSAL_INSTANCE = UserService.msalInstanceFactory();
      this.MSAL_INSTANCE.initialize();
    }
  }

  private sentryUserSetup() {
    this.currentUser.subscribe((user) => {
      const sentryUser = !!user ? { email: user.email, id: user.canonicalId || null } : {};

      Sentry.withScope((scope) => {
        scope.setUser(sentryUser);
      });
    });
  }

  private actionsForError() {
    this.dataService.allErrors
      .pipe(
        // eslint-disable-next-line complexity
        filter((error: DSHttpError) => {
          const invalidSession =
            error.code.match(/(fourOhOne|fourOhThree)/) ||
            get(error, 'data.status') === 401 ||
            get(error, 'data.status') === 403;
          return invalidSession && !!this.user;
        }),
      )
      .pipe(switchMap(() => from(this.logout())))
      .subscribe();
  }

  /**
   * For more info, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/configuration.md
   */
  public static msalInstanceFactory = (): IPublicClientApplication => {
    if (UserService.hasB2CConfig()) {
      return new PublicClientApplication(environment.azureB2C.msalConfig);
    }
  };

  public getIPLocation() {
    this.ipLocationService.isFirebaseRestricted.subscribe((isFirebaseRestricted) => {
      this.useFirebaseServer = isFirebaseRestricted;
    });

    this.ipLocationService.getLocation().subscribe();
  }

  private subscribeToAuthEvents() {
    if (!this.authSubscription) {
      this.authSubscription = this.af.user.subscribe(
        (afUser) => this.authenticated(afUser),
        (error) => this.log.error(error),
      );
    }
  }

  private getCookieUserIdVal() {
    return jsCookie.get(this.canonicalIdCookieName);
  }

  private subscribeToCheckLoggedUser() {
    this.zone.runOutsideAngular(() => {
      const loggedUserCheckInterval = 1000;
      if (!this.loggedUserCheckSubscription) {
        this.loggedUserCheckSubscription = timer(0, loggedUserCheckInterval).subscribe(
          () => {
            const cookieVal = this.getCookieUserIdVal();
            if (this.user?.canonicalId && this.user.canonicalId !== cookieVal) {
              this.loggedUserCheck$.next(true);
            }
          },
          (error) => {
            this.log.error(error);
          },
        );
      }
    });
  }

  // eslint-disable-next-line class-methods-use-this
  private baseHeaders(additional?: { [key: string]: string }): { [key: string]: string } {
    return {
      'Content-Type': 'auth/token',
      Accept: 'auth/token; v1, application/json',
      ...additional,
    };
  }

  public refreshTokenBeforeNavigate(): Observable<User> {
    return this.tokenRefresh();
  }

  public tokenRefresh(): Observable<User> {
    return this.dataService.put(`${this.serviceUrl}token`, {}, this.tokenRefreshOptions()).pipe(
      switchMap((status) => this.getUser(status)),
      tap(() => {
        this.log.info('token refreshed');
      }),
    );
  }

  private tokenRefreshOptions() {
    return {
      error$: this.userError$,
      expectStatus: 204,
      unexpectedStatusCode: 'auth.generic',
      defaultErrorCode: 'auth.generic',
      errorCodes: {
        '403': UCErrorCodes.E401,
      },
      includeHeaders: this.baseHeaders(),
      deserialize: (_, res): number => res.status,
    };
  }

  public startRefresh(timeout: number): void {
    if (environment.e2e) {
      this.log.info('disabling the token refresh for e2e tests');
      return;
    }

    const refreshAt = DateTime.now().plus({ seconds: timeout });
    this.log.info('Will refresh token in ', timeout, ' seconds at', refreshAt.toISO());

    const startDelay = timeout * 1000;
    const refreshInterval = startDelay;
    this.tokenRefreshInInterval(startDelay, refreshInterval);
  }

  private tokenRefreshInInterval(startDelay: number, refreshInterval: number) {
    this.lastTokenSubscription = timer(startDelay, refreshInterval)
      .pipe(
        switchMap(() => this.tokenRefresh()),
        switchMap(() => {
          if (!this.user) {
            this.log.info('refresh called when no logged in user');
            return throwError(new HttpResponse({ status: 401 }));
          } else {
            return of(true);
          }
        }),
      )
      .subscribe({
        error: (error) => {
          this.log.error(error);
        },
      });
  }

  private testCorrectLogin(uid) {
    return this.loginUid && uid && this.loginUid === uid;
  }

  // eslint-disable-next-line max-lines-per-function, complexity
  private authenticated(afUser: firebase.User): void {
    if (afUser) {
      // Provide a way to know that we are logged in via firebase
      this.firebaseUser = afUser;

      const justBeenCalled = this.lastAuthTokenAt && new Date().getTime() - this.lastAuthTokenAt.getTime() < 300;
      if (this.user || justBeenCalled) {
        // Don't get auth token if we already have one
        return;
      }

      if (!this.testCorrectLogin(afUser.uid)) {
        if (this.loginUid) {
          this.log.warn('Firebase attempted login for incorrect user');
        }
        return;
      }

      if (!afUser.displayName && this.isCreatingAccount) {
        return;
      }
      this.isCreatingAccount = false;

      this.log.info('firebase auth change:', { ...afUser });
      afUser
        .getIdToken()
        // eslint-disable-next-line complexity
        .then((afToken) => {
          const newUserRecentAuth =
            this.newUser?.email === afUser.email &&
            this.lastAuthTokenAt &&
            DateTime.now().diff(DateTime.fromJSDate(this.lastAuthTokenAt)).milliseconds < 2000;
          if (newUserRecentAuth) {
            return;
          }
          this.lastAuthTokenAt = new Date();
          this.log.info('new firebase token:', afToken);
          this.saveFirebaseToken(afToken);
        })
        .catch((error) => this.log.error(error));
    } else if (this.firebaseUser && this.user && !this.loggingOut) {
      this.logout().catch((error) => this.log.error('Failed to logout', error));
    }
  }

  // eslint-disable-next-line max-lines-per-function
  public getUser(status?: number): Observable<User> {
    return this.dataService
      .fetch(`${this.serviceUrl}me`, {
        expectStatus: 200,
        unexpectedStatusCode: 'auth.generic',
        defaultErrorCode: UCErrorCodes.E401,
        errorCodes: {
          '401': UCErrorCodes.E401,
        },
        deserialize: (payload): UserDetail => UserDetail.deserialize(payload),
        ignoredErrorStatuses: [401],
      })
      .pipe(
        map((userDetail: UserDetail) => {
          this.userDetail$.next(userDetail);
          const user = userDetail.impersonated || userDetail.mainUser;
          user.partiallyHydrated = status === 206;
          this.user$.next(user);
          this.setUserCookie(user.canonicalId);
          this.subscribeToCheckLoggedUser();
          if (!this.hasTokenSubscription) {
            this.startRefresh(userDetail.timeout);
          }
          return user;
        }),
        catchError((error: DSHttpError) => {
          return throwError(error);
        }),
      );
  }

  private setUserCookie(canonicalId: string) {
    jsCookie.set(this.canonicalIdCookieName, canonicalId, { expires: 1 });
  }

  // eslint-disable-next-line max-lines-per-function
  public getAliases(canonicalId: string): Observable<UserAlias> {
    return this.dataService
      .fetch(`${this.serviceUrl}staff/${canonicalId}/aliases`, {
        expectStatus: 200,
        unexpectedStatusCode: 'auth.generic',
        defaultErrorCode: UCErrorCodes.E401,
        errorCodes: {
          '401': UCErrorCodes.E401,
        },
        deserialize: (payload): UserAlias => UserAlias.deserialize(payload),
        ignoredErrorStatuses: [401],
      })
      .pipe(
        map((aliases: UserAlias) => {
          return aliases;
        }),
        catchError((error: DSHttpError) => {
          return throwError(error);
        }),
      );
  }

  private destroyToken(scope?: UserTypes): Observable<null> {
    let url = `${this.serviceUrl}token`;
    if (scope) {
      url = url.concat(`?scope=${scope}`);
    }
    return this.dataService
      .del(url, {
        unexpectedStatusCode: 'auth.generic',
        defaultErrorCode: 'auth.generic',
      })
      .pipe(tap(() => this.cacheService.clearCache()));
  }

  // eslint-disable-next-line max-lines-per-function
  public saveFirebaseToken(token: string): void {
    const params = new HttpParams().set('scope', environment.scope);
    this.dataService
      .post(
        `${this.serviceUrl}token`,
        {},
        {
          // we handle errors manually
          emitErrors: false,
          unexpectedStatusCode: 'auth.generic',
          defaultErrorCode: 'auth.generic',
          errorCodes: {
            '403': UCErrorCodes.E401,
            '409': 'auth.fourOhNine',
          },
          requestOptions: {
            headers: new HttpHeaders(
              this.baseHeaders({
                'Content-Type': 'auth/jwt',
                Accept: 'auth/jwt; v1, application/json',
                Authorization: `JWT ${token}`,
              }),
            ),
            params,
          },
          deserialize: (_, res): number => res.status,
        },
      )
      .subscribe({
        next: (status) => {
          this.getUser(status).subscribe();
        },
        // eslint-disable-next-line max-lines-per-function, complexity
        error: (error: DSHttpError) => {
          // Stop listening on the firebase login events, next login attempt
          // will set up the listener anyway
          if (this.authSubscription) {
            this.authSubscription.unsubscribe();
            this.authSubscription = null;
          }

          /*
           This is a bit of a hack, but we check err.code instead of `err.status === 422`
           because if the fourTwentyTwoDeserializer throws an error, then
           the err.status === 422 but err.data is the error from fourTwentyTwoDeserializer
           and not the correct payload that we need to use to check for email validation errors
           */
          if (error.code === UCErrorCodes.E422) {
            const isEmailVerificationError = error.data.find((reason) => reason.source.pointer.match(/emailVerified/));
            if (isEmailVerificationError) {
              error.code = 'auth.emailUnverified';
              this.showVerificationModal();
            }
          }
          this.log.error('Error requesting a new token');
          this.userError$.next(error);
          this.dataService.allErrors$.next(error);
        },
      });
  }

  /**
   * Create Custom Account (Firebase)
   * User fills out form
   * Submits
   * User Service:
   * - Deserialize the form data into a client-side User obj (newUser)
   * - Submit email/password to firebase#createUser
   * - Update new account with displayName
   * - Login with email/password
   * - Firebase token now has everything the Token MS need.
   * Token MS:
   * - Receive token and user, validate token
   * - generate UC Token with user, respond
   * User Service:
   * - Receive token, parse user obj from token
   * - Login that user
   */
  // eslint-disable-next-line max-lines-per-function
  public createAccount(
    userFormData: UserFormData,
  ): Promise<firebase.auth.UserCredential | firebase.auth.OAuthCredential> {
    if (this.useFirebaseServer) {
      return this.serverCreateAccount(userFormData);
    }

    this.subscribeToAuthEvents();

    this.isCreatingAccount = true;
    this.newUser = new User(userFormData);
    const cloneUser = { ...this.newUser };
    const { email, password } = userFormData;
    this.log.info('creating new account for ', email);
    return this.af
      .createUserWithEmailAndPassword(email, password)
      .then(({ user: afUser }: firebase.auth.UserCredential) => {
        this._loginUid = afUser.uid;
        this.log.info('requesting firebase profile update for new user');
        return afUser
          .updateProfile({
            displayName: [cloneUser.lastName, cloneUser.firstName].join(', ').trim(),
            photoURL: cloneUser.imageURL,
          })
          .then(() => {
            this.sendEmailVerification(afUser);
            this.log.info('The users display name: ', afUser.displayName);
          });
      })
      .then(() => {
        this.log.info('loginFirebaseEmail');
        return this.loginFirebaseEmail(email, password);
      })
      .catch((error) => {
        this.handleFirebaseError(error);
        return error;
      });
  }

  public serverCreateAccount(userFormData: UserFormData): Promise<firebase.auth.OAuthCredential> {
    return this.dataService
      .post(`${this.firebaseServerURL}/user`, { user: userFormData }, { emitErrors: false })
      .pipe(
        catchError((error) => throwError(this.handleServerFirebaseError(error))),
        tap((response) => this.saveFirebaseToken(response.idToken)),
        tap(() => this.logFirebaseServerUsage('Account create')),
      )
      .toPromise();
  }

  private handleServerFirebaseError(error: DSHttpError): DSHttpError {
    const fbError = get(error, 'data.error', { code: 'auth/unknown-error' });
    this.handleFirebaseError(fbError);
    return error;
  }

  public serverLoginFirebaseEmail(email: string, password: string): Promise<firebase.auth.OAuthCredential> {
    const user = { email, password };
    return this.dataService
      .post(`${this.firebaseServerURL}/authenticate`, { user }, { emitErrors: false })
      .pipe(
        catchError((error) => throwError(this.handleServerFirebaseError(error))),
        tap((response) => this.saveFirebaseToken(response.idToken)),
        tap(() => this.logFirebaseServerUsage('Login')),
      )
      .toPromise();
  }

  private logFirebaseServerUsage(authAction: string) {
    Sentry.withScope((scope) => {
      scope.setTag('country', this.ipLocationService.currentCountryCode());
      Sentry.captureMessage(`${authAction} occurred via Firebase server`);
    });
  }

  public loginFirebase(type: string) {
    this.subscribeToAuthEvents();
    const providers = {
      Google: GoogleAuthProvider,
      Facebook: FacebookAuthProvider,
    };
    const provider = providers[type];

    this.af
      .signInWithPopup(new provider())
      .then((cred: firebase.auth.UserCredential) => {
        this._loginUid = cred.user.uid;
        if (type === 'Google') {
          const firstName: string = cred.additionalUserInfo.profile['given_name'];
          const lastName: string = cred.additionalUserInfo.profile['family_name'];
          const displayName = [lastName, firstName].join(', ').trim();
          cred.user
            .updateProfile({
              displayName: displayName,
            })
            .then(() => {
              this.log.info(`The users display name on Firebase has been updated using ${type}`);
            });
        }
        if (type === 'Facebook') {
          const firstName = cred.additionalUserInfo.profile['first_name'];
          const lastName = cred.additionalUserInfo.profile['last_name'];
          const displayName = [lastName, firstName].join(', ').trim();
          this._loginUid = cred.user.uid;
          return cred.user
            .updateProfile({
              displayName: displayName,
            })
            .then(() => {
              this.log.info(`The users display name on Firebase has been updated using ${type}`);
            });
        }
      })
      .catch((error) => {
        this.handleFirebaseError(error);
      }) as unknown as Promise<firebase.auth.UserCredential>;
  }

  // eslint-disable-next-line max-lines-per-function
  public loginFirebaseEmail(
    email: string,
    password: string,
  ): Promise<firebase.auth.UserCredential | firebase.auth.OAuthCredential> {
    if (this.useFirebaseServer) {
      return this.serverLoginFirebaseEmail(email, password);
    }

    this.subscribeToAuthEvents();
    return this.af
      .signInWithEmailAndPassword(email, password)
      .then((credential: firebase.auth.UserCredential) => {
        this.checkFirebaseEmailLogin().then((fbEmailLogin) => {
          this.isFirebaseEmailLogin$.next(fbEmailLogin);
        });
        this._loginUid = credential.user.uid;
        return credential;
      })
      .catch((error) => {
        this.handleFirebaseError(error);
        return error;
      });
  }

  // eslint-disable-next-line max-lines-per-function
  public loginUC(username: string, password: string): Promise<User> {
    const params = new HttpParams().set('scope', environment.scope);
    // eslint-disable-next-line max-lines-per-function
    return new Promise((resolve, reject) => {
      // Note: an errors$ subject is not provided, because we manually handle loginUC failures with fallbacks
      return this.dataService
        .post(
          `${this.serviceUrl}token`,
          {},
          {
            requestOptions: {
              headers: new HttpHeaders(
                this.baseHeaders({
                  'Content-Type': 'auth/basic',
                  Authorization: `Basic ${btoa(`${username}:${password}`)}`,
                }),
              ),
              params,
            },
            errorCodes: {
              '403': 'auth.ucUserNotFound',
              '409': 'auth.fourOhNine',
            },
            deserialize: (_, res): number => res.status,
          },
        )
        .subscribe({
          next: (status) => {
            this.getUser(status).subscribe(resolve);
          },
          error: reject,
        });
    });
  }

  public getActiveB2CUser(): B2CAccountInfo {
    if (UserService.hasB2CConfig()) {
      return this.msalService.instance.getActiveAccount();
    } else {
      this.log.warn('MSAL Service: No Azure B2C config has been provided.');
    }
  }

  public loginB2C() {
    // MSAL requires InteractionStatus to be None before performing a loginRedirect
    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        first(),
      )
      .subscribe(() => {
        try {
          this.MSAL_INSTANCE.loginRedirect(environment.azureB2C.loginRequest);
          this.log.info('*** B2C loginRedirect');
        } catch (error) {
          this.log.error('B2C loginRedirect failed:', error);
        }
      });
  }

  public handleRedirectObservable() {
    this.azureB2CRedirectSubscription = this.msalService.handleRedirectObservable().subscribe({
      next: (result: AuthenticationResult) => {
        if (result && !this.isB2CLogin) {
          this.MSAL_INSTANCE.setActiveAccount(result?.account);
          this.saveB2CToken(result?.idToken);
        }
      },
      error: (error) => {
        // eslint-disable-next-line no-console
        console.error('B2C AuthenticationResult failed:', error);
        this.ws.nativeWindow.location.reload();
      },
    });
  }

  public saveB2CToken(idToken: string) {
    this.getActiveB2CUser();

    if (environment.scope !== UserTypes.student || environment.subScope !== 'ucOnline') {
      this.log.error(`Unexpected scope: ${environment.scope} ${environment.subScope}`);
      return;
    }

    const params = new HttpParams().set('scope', environment.scope).set('source_type', 'b2c');
    this.dataService
      .post(`${this.serviceUrl}azureb2c/token`, {}, this.b2cTokenRequestOptions(idToken, params))
      .subscribe((status) => {
        const cookieVal = this.getCookieUserIdVal();
        this.log.info('*** MyUCSession cookie:', cookieVal);
        this.getUser(status).subscribe(() => {
          this.isB2CLogin$.next(true);
        });
      });
  }

  private b2cTokenRequestOptions(idToken, params) {
    return {
      requestOptions: {
        headers: new HttpHeaders(
          this.baseHeaders({
            'Content-Type': 'auth/jwt',
            Accept: 'auth/jwt; v1, application/json',
            Authorization: `JWT ${idToken}`,
          }),
        ),
        params,
      },
      errorCodes: {
        '403': 'auth.ucUserNotFound',
        '409': 'auth.fourOhNine',
      },
      deserialize: (_, res): number => res.status,
    };
  }

  public async logoutB2C(): Promise<void> {
    this.log.info('Azure B2C logout initiated');
    await this.dataService.del(`${this.serviceUrl}token/`, { includeHeaders: this.baseHeaders() }).toPromise();
    if (this.getActiveB2CUser()) {
      return await this.MSAL_INSTANCE.logoutRedirect();
    } else {
      this.log.warn('Attempted to log out of Azure B2C without active user.');
    }
  }

  /**
   * This call can be used to wrap api calls that require
   * masquerade functionality (staff user makes call on behalf of student).
   *
   * Follow up with destroyStudentTokenForStaff after the api call completes.
   *
   * @param canonicalId
   */
  public createStudentTokenForStaff(canonicalId): Observable<null> {
    return this.getStudentToken(canonicalId).pipe(map(() => null));
  }

  /**
   * See createStudentTokenForStaff
   */
  public destroyStudentTokenForStaff(): Observable<null> {
    return this.destroyToken(UserTypes.student);
  }

  private getStudentToken(canonicalId): Observable<number> {
    return this.dataService.post(
      `${this.serviceUrl}token/${canonicalId}`,
      {},
      {
        error$: this.userError$,
        expectStatus: 204,
        unexpectedStatusCode: 'auth.generic',
        defaultErrorCode: 'auth.generic',
        includeHeaders: this.baseHeaders(),
        deserialize: (_, response): number => response.status,
      },
    );
  }

  public impersonate(canonicalId): Observable<User> {
    return this.getStudentToken(canonicalId).pipe(switchMap((status) => this.getUser(status)));
  }

  public unimpersonate(): Observable<User> {
    const cid = get(this.user$.value, 'canonicalId');
    return this.destroyToken(UserTypes.student).pipe(
      switchMap(() => this.getUser()),
      tap(() => {
        if (environment.scope === UserTypes.staff) {
          this.cookieIdToClear$.next(cid);
          this.router.navigate(internalUrls.studentSummary(cid));
        } else {
          this.router.navigate(internalUrls.dashboard);
        }
      }),
    );
  }

  public impersonateAndNavigate(canonicalId: string, urlParts: string[]): Observable<boolean> {
    return this.impersonate(canonicalId).pipe(switchMap(() => from(this.router.navigate(urlParts))));
  }

  // eslint-disable-next-line max-lines-per-function
  private showVerificationModal() {
    if (!this.useFirebaseServer) {
      this.modal
        .confirm()
        .size('lg')
        .isBlocking(true)
        .showClose(true)
        .title(this.strings.title)
        .body(this.strings.body)
        .okBtn(this.strings.ok)
        .cancelBtn(this.strings.cancel)
        .cancelBtnClass('cancel-btn')
        .open()
        .result.then(() => {
          this.af.currentUser.then((user) => {
            return user.sendEmailVerification();
          });
        })
        .then(() => {
          return this.af.signOut();
        })
        .catch((er) => {
          this.log.error('error during modal email verification handling', er);
        })
        .then(() => document.body.classList.remove('modal-open'));
    } else {
      this.modal
        .alert()
        .size('lg')
        .isBlocking(true)
        .showClose(true)
        .title(this.strings.title)
        .body(this.strings.chinaBody)
        .open()
        .result.catch((er) => {
          this.log.error('error during modal email verification handling', er);
        })
        .then(() => document.body.classList.remove('modal-open'));
    }
  }

  // eslint-disable-next-line max-lines-per-function, complexity
  public formatFirebaseError(error) {
    // Map of known errors
    const errorMap = firebaseErrorMap;

    // Map of possible error patterns
    const textMap = [
      {
        pattern: /(not available in|not available from|Iran|Sudan|North Korea|Syria)/i,
        code: 'auth.countryBlocked',
      },
    ];

    const textMatchLookup = (textPatternArr, message) => {
      const match = textPatternArr.find((el) => {
        return message && message.match(el.pattern);
      });
      return match && match.code;
    };

    const ucError = {
      code: errorMap[error.code] || textMatchLookup(textMap, error.message) || 'auth.genericFirebase',
      data: error,
    };

    if (ucError.code === 'auth.genericFirebase') {
      const errorMessage = `Firebase error. UI error code: ${ucError.code}`;
      this.log.error(`${errorMessage}`, error);
      Sentry.captureException(new Error(`${errorMessage}. Original Firebase error: ${error.code} - ${error.message}`));
    }

    return ucError;
  }

  private handleFirebaseError(error) {
    this.zone.run(() => this.userError$.next(this.formatFirebaseError(error)));
  }

  private logoutFirebase(): Promise<void> {
    this.log.info('Firebase logout initiated');
    // Prevent double logout
    this.loggingOut = true;
    return this.af.signOut().then(() => {
      this.isFirebaseEmailLogin$.next(false);
      this.log.info('Firebase logout complete');
    });
  }

  // eslint-disable-next-line complexity
  public cleanupState(redirectUrl?): void {
    const redirectionUrl = redirectUrl || internalUrls.login;
    this.userDetail$.next(null);
    this.user$.next(null);

    this.dataService.del(`${this.serviceUrl}token/`, { includeHeaders: this.baseHeaders() }).subscribe();

    if (environment.outsystemsUrl) {
      this.ws.nativeWindow.location.href = environment.outsystemsUrl;
    } else if (UserService.hasB2CConfig() && environment.subScope === UserSubTypes.ucOnlineStudent) {
      this.isB2CLogin$.next(false);
      this.msalService.instance.setActiveAccount(null);
      window.location.assign(environment.hubSpotUrl);
    } else {
      this.router.navigate(redirectionUrl).then(() => this.ws.nativeWindow.location.reload());
    }
  }

  // eslint-disable-next-line max-lines-per-function
  public logout(redirectUrl?): Promise<void> {
    if (!this.user) {
      const errorMessage = 'Trying to logout with no user.';
      this.log.error(errorMessage);
      return Promise.reject(new Error(errorMessage));
    }

    if (!this.modalService.shouldNavigate) {
      this.modalService.navigationWarning(this.logout.bind(this));
      return Promise.reject(new Error('Unsaved form prevented logout'));
    }

    this.log.info('Logging out', this.user);
    return this.af.currentUser
      .then((user) => {
        if (user) {
          return this.logoutFirebase();
        } else if (UserService.hasB2CConfig()) {
          return this.logoutB2C();
        } else {
          return;
        }
      })
      .catch((error) => {
        this.log.warn('Failed to log out of firebase: ', error);
      })
      .then(() => this.destroyToken().toPromise())
      .catch(() => {
        this.log.error('Failed to destroy token, not logging out...');
      })
      .then(() => this.cleanupState(redirectUrl));
  }

  public checkFirebaseEmailLogin(): Promise<boolean> {
    return new Promise((resolve) => {
      if (this.useFirebaseServer) {
        return resolve(true);
      }
      this.af.currentUser.then((user) => {
        const firstProvider = user?.providerData && user?.providerData[0];
        const isFirebaseEmailLogin =
          firstProvider && firstProvider.providerId === firebase.auth.EmailAuthProvider.PROVIDER_ID;
        return resolve(isFirebaseEmailLogin);
      });
    });
  }

  public revalidate(password): Promise<firebase.auth.UserCredential> {
    return this.af.currentUser.then((user) => {
      return this.af.signInWithEmailAndPassword(user?.email, password).catch((error) => {
        this.handleFirebaseError(error);
        throw error;
      });
    });
  }

  public updateEmail(email: string): Promise<void> {
    return this.af.currentUser.then((user) => {
      user.updateEmail(email).catch((error) => {
        this.handleFirebaseError(error);
        throw error;
      });
    });
  }

  public updatePassword(password: string): Promise<void> {
    return this.af.currentUser.then((user) => {
      user.updatePassword(password).catch((error) => {
        this.handleFirebaseError(error);
        throw error;
      });
    });
  }

  public verifyEmail(): Promise<void> {
    return this.af.currentUser.then((user) => {
      user.sendEmailVerification().catch((error) => {
        this.handleFirebaseError(error);
        throw error;
      });
    });
  }

  /**
   * attempt to send a password reset email using firebase
   *
   * When input is not an email (abc123) then resolve with {state: isUcAddress}
   * When an input is firebase account, and it can be reset, resolve with {state: success}
   * When the input does not exist in firebase:
   * - if input is a uc email, resolve with {state: isUcAddress}
   * - otherwise resolve with {state: success} (we don't want to give information about which emails are found in our system)
   */
  public resetEmail(email: string): Promise<{ state: string }> {
    const success = Promise.resolve({ state: 'success' });
    const isUC = Promise.resolve({ state: 'isUCAddress' });
    if (!environment.features.sendEmail) {
      return success;
    }
    if (!email.match(isEmailRegex)) {
      return isUC;
    }
    return this.af
      .sendPasswordResetEmail(email)
      .then(() => success)
      .catch((afError) => {
        if (afError?.code === 'auth/user-not-found') {
          return email.match(ucEmailRegex) ? isUC : success;
        }
        return Promise.reject(afError);
      });
  }

  public staffSearchUser(query: string, type: string): Observable<AdminViewUser[]> {
    const url = `${this.serviceUrl}staff/admin/user?query=${query}&type=${type}`;
    return this.dataService.post(
      url,
      {},
      {
        deserialize: AdminViewUser.deserialize,
      },
    );
  }

  public staffForceHydrateOnLogin(identifier: string, value: boolean) {
    const userInfo = { user: { cid: identifier, needs_hydrating: value } };
    return this.dataService.put(`${this.serviceUrl}staff/hydration`, userInfo, {});
  }

  private sendEmailVerification(user: firebase.User): Promise<void> {
    if (!environment.features.sendEmail) {
      return Promise.resolve();
    }

    return user
      .sendEmailVerification()
      .catch((e) => {
        this.log.error('error sending verification email', e);
        throw e;
      })
      .then(() => {
        this.log.info('verification email sent');
      });
  }

  public checkClaim(claim) {
    return this.userClaims && this.userClaims.indexOf(claim) !== -1;
  }

  // eslint-disable-next-line complexity
  public maybeUpdateFirebaseName(applicant: Applicant) {
    const appFirstname = get(applicant, 'legalName.firstName');
    const appLastName = get(applicant, 'legalName.surname');
    if (appFirstname && appLastName && this.af.currentUser && !get(this.af, 'currentUser.displayName')) {
      const user = new User({ ...this.user$.value });
      user.firstName = appFirstname;
      user.lastName = appLastName;
      this.af.currentUser.then((currUser) => {
        currUser
          .updateProfile({
            displayName: user.displayName,
            photoURL: currUser.photoURL || null,
          })
          .then(() => {
            this.user$.next(user);
          });
      });
    }
  }

  // eslint-disable-next-line max-lines-per-function
  public outageListener() {
    this.notificationService.notifications
      .pipe(
        filter((notifications: Notification[]) => !!notifications && !!notifications.length),
        map((notifications: Notification[]) => {
          return notifications.filter((not) => {
            return not.type === NotificationTypes.outage && not.expiry > new Date();
          });
        }),
        map((notifications: Notification[]) => notifications.sort((a, z) => a.expiry.getTime() - z.expiry.getTime())),
        map((notification) => notification[0]),
      )
      .subscribe({
        next: (notification) => {
          if (notification) {
            this.expiryScheduler(notification);
            this.logoutScheduler(notification.expiry);
          }
        },
        error: (error) => {
          Sentry.captureException(error);
          this.log.error('Notification listener error', error);
        },
      });
  }

  // eslint-disable-next-line class-methods-use-this
  private timeDifference(time: Date) {
    const difference = time.getTime() - new Date().getTime();
    return difference > 0 ? difference : null;
  }

  public expiryScheduler(notification: Notification) {
    const outageNoticeCountdown = this.timeDifference(notification.start);
    if (outageNoticeCountdown) {
      timer(outageNoticeCountdown).subscribe({
        error: (error) => {
          Sentry.captureException(error);
          this.log.error('Outage notice timer error', error);
        },
        complete: () => this.outageNotification$.next(notification),
      });
    }
  }

  public logoutScheduler(expiryTime: Date) {
    const logoutCountdown = this.timeDifference(expiryTime);
    if (logoutCountdown) {
      timer(logoutCountdown).subscribe({
        error: (error) => {
          Sentry.captureException(error);
          this.log.error('Notification timer error', error);
        },
        complete: () => {
          this.outageNotification$.next(null);
          if (this.user$.value) {
            this.logout(internalUrls.outage).catch((error) => this.log.error('Failed to logout', error));
          } else {
            this.router.navigate(internalUrls.outage);
          }
        },
      });
    }
  }
}
