import { Component, Input } from '@angular/core';

@Component({
  selector: 'uc-tab',
  templateUrl: './uc-tab.component.html',
  styleUrls: ['./uc-tab.component.scss'],
})
export class UcTabComponent {
  @Input() title: string;
  @Input() active = false;
}
