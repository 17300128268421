import { Component, OnInit, Input, forwardRef, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import strings from '@constants/strings.constants';
import hintStringsConstants from '@shared/constants/hint-strings.constants';
import { UCError } from '@shared/models/errors';
import { LoggingService, Logger } from '@shared/services/logging/logging.service';

@Component({
  selector: 'uc-string-input',
  templateUrl: './string-input.component.html',
  styleUrls: ['./string-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => StringInputComponent),
      multi: true,
    },
  ],
})
export class StringInputComponent implements OnInit, ControlValueAccessor {
  @Input() label: string;
  @Input() required: boolean;
  @Input() noIcon: boolean;
  @Input() asyncErrorPointer: string;
  @Input() errors: { [key: string]: unknown }[];
  @Input() isNumeric: boolean;
  @Input() isDecimal: boolean;
  @Input() step: number;
  @Input() max: number;
  @Input() min: number;
  @Input() placeholder: string;
  @Input() maxLength: number;
  @Input() showDisabledExplanation = true;
  @Input() testSelector = '';
  @Input() prefix = '';
  @Input() errorMessage;

  @Output() blurred = new EventEmitter();

  inputControl = new UntypedFormControl('');
  strings = strings;
  asyncError: UCError;
  hasValue = false;
  isDisabled: boolean;
  maxLengthLimit = false;
  maxLengthError: string;

  log: Logger;

  private propagateChange: (_: string) => void;
  private propagateTouch: (_: boolean) => void;

  constructor(loggingService: LoggingService) {
    this.log = loggingService.createLogger(this);
  }

  get testSelectorId() {
    return `${this.testSelector}-input`;
  }

  get hasPrefix() {
    return this.prefix === '' ? false : true;
  }

  ngOnInit() {
    this.log.info('ngOnInit');
    this.inputControl.valueChanges.subscribe((text) => {
      this.hasValue = !!text.length;
      this.propagateChange?.(text);
    });
    if (this.maxLength) {
      this.maxLengthError = hintStringsConstants.hints.maxCharacters(this.maxLength);
    }
  }

  stripInvalidCharacters() {
    let reg = /[^ \f\r\n-~]/gi;
    if (this.isNumeric || this.isDecimal) {
      reg = /[^0-9.]/g;
    }
    setTimeout(() => {
      const val = this.inputControl.value;
      if (val && val.match(reg)) {
        this.inputControl.setValue(val.replace(reg, ''));
      }
    });
  }

  public limitText() {
    if (!this.maxLength) {
      return;
    }
    if (this.inputControl.value.length >= this.maxLength) {
      this.inputControl.setValue(this.inputControl.value.substring(0, this.maxLength));
    }

    this.maxLengthLimit = this.inputControl.value.length >= this.maxLength;
  }

  writeValue(obj: string): void {
    if (obj) {
      this.hasValue = true;
    } else {
      this.hasValue = false;
    }
    this.inputControl.setValue(obj, { emitEvent: false });
  }

  registerOnChange(fn: (_: string) => void): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: (_: boolean) => void): void {
    this.propagateTouch = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.isDisabled = isDisabled;
  }
}
