import { Component, AfterContentInit, ContentChildren, QueryList } from '@angular/core';

import { UcTabComponent } from '../uc-tab/uc-tab.component';

@Component({
  selector: 'uc-tab-group',
  templateUrl: './uc-tab-group.component.html',
  styleUrls: ['./uc-tab-group.component.scss'],
})
export class UcTabGroupComponent implements AfterContentInit {
  @ContentChildren(UcTabComponent) tabs: QueryList<UcTabComponent>;
  currentTab: UcTabComponent;

  ngAfterContentInit() {
    const activeTabs = this.tabs.filter((tab) => tab.active);

    if (activeTabs.length === 0) {
      this.selectTab(this.tabs.first);
    }
  }

  selectTab(tabActive: UcTabComponent) {
    this.deactivateAllTabs();
    tabActive.active = true;
    this.currentTab = tabActive;
  }
  deactivateAllTabs() {
    this.tabs.toArray().forEach((tabItem) => (tabItem.active = false));
  }
}
