/**
* 1. Project-wide color variables.
* Uses http://chir.ag/projects/name-that-color/ to generate
* appropriate colour variable name.
*/
$colorMonza: #c01;
$colorMonzaTransparent: rgb(204 0 17 / 5%);
$colorBlueRibbon: #066ce8;
$colorJade: #00aa6e;
$colorAmber: #ffc000;
$colorMineShaft: #292929;
$colorAbbey: #45494c;
$colorRollingStone: #727779;
$colorOsloGrey: #929598;
$colorDustyGrey: #979797;
$colorSilverSand: #c4c7ca;
$colorGhost: #ced4da;
$colorMercury: #e1e1e1;
$colorConcrete: #f3f3f3;
$colorAlabaster: #fcfcfc;
$colorWhite: #fff;
$colorWhiteTransparent90: rgb(255 255 255 / 90%);
$colorWaikawaGray: #5d73af;
$colorPunch: #dd4b39;
$colorPunchTransparent66: rgb(255 209 209 / 66%);
$colorEmerald: #46c078;
$colorEmeraldTransparent: rgb(70 192 120 / 10%);
$colorBondiBlue: #00aab4;
$colorFireEngineRed: #d7252c;
$colorVermilion: #ff4900;
$colorIndigo: #46126d;
$colorFrenchRose: #f2478c;
$colorTurquoise: #00f9cf;
$colorLightGrey: #ddd;
$colorNavajoWhite: #ffdba9;

// Figma colors
$colorNeutral3: #e9e9e9;
$colorNeutral7: #898f93;
$colorNeutral10: #161e2c;

// ourUC color palette
$colorErrorRed: #ee0004;
$colorErrorBackgroundRed: #fff9f7;
$colorBrightRed: #a7000c;
$colorMaroon: #7e0009;
$colorScienceBlue: #0453c7;
$colorCobalt: #033ea7;
$colorWarning: #ff9344;
$gradientEmeraldToBondiBlue: linear-gradient(to right, $colorEmerald 0%, $colorBondiBlue 100%);

// Online UI color palette
$colorPrimaryGrey: #606060;
$colorHoverGrey: #45494c;

/**
* 2. Shared color variables
* Color variables for elements used within more than one
* Angular component.
*/

/**
* Generic form input colors
*/
$fieldLabelTextColor: $colorAbbey;
$fieldInputBgColor: $colorWhite;
$fieldInputTextColor: $colorAbbey;
$fieldInputBorderColor: $colorMercury;
$fieldDisabledBgColor: $colorConcrete;
$fieldDisabledBorderColor: $colorConcrete;
$fieldValidationUntouchedBgColor: $colorMercury;
$fieldValidationValidBgColor: $colorJade;
$fieldValidationInvalidBgColor: $colorMonza;
$fieldValidationIconFill: $colorWhite;
$fieldExplanationLinkTextColor: $colorAbbey;
$fieldRequiredAsteriskColor: $colorMonza;
$fieldErrorTextColor: $colorMonza;
$checkableFieldHoverBgColor: $colorConcrete;

/**
* Link text
*/
$defaultLinkTextColor: $colorBlueRibbon;
