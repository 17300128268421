@import "tools";

/**
* Settings
*/
$pageBgColor: $colorWhite;

/**
* Component Styles
*/

:host {
  display: block;
  margin-bottom: 50px;
}

uc-form-header {
  display: block;
  margin: 0 auto 30px auto;
}

.form-title {
  margin-bottom: 20px;
  font-size: uc-rem-convert(32);
}

.full-page {
  margin: 0 auto;
  background: $pageBgColor;
}

.heading-para {
  font-size: uc-rem-convert(14);
  line-height: 1.36;
  padding-bottom: 32px;
}

uc-message-banner {
  display: block;
  margin-bottom: 10px;
}
