import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { Router } from '@angular/router';
import { get, flattenDeep } from 'lodash-es';
import { EMPTY } from 'rxjs';
import { catchError, concatMap, filter, map, switchMap } from 'rxjs/operators';

import strings from '@constants/strings.constants';
import { APPLICATION_ACTIONS } from '@shared/constants/actions.constants';
import { PROCESS_NAMES } from '@shared/constants/app-names.constants';
import { internalUrls } from '@shared/constants/internalUrls';
import {
  ENROLMENT_STATE,
  APPLICATION_STATE,
  SUMMARY_TYPES,
  APPLICATION_CHANGE_STATE,
  OFFER_DECISION_STATE,
} from '@shared/constants/states.constants';
import { externalUrls } from '@shared/constants/urls.constants';
import { isUcoStudent } from '@shared/helpers/isUcoStudent';
import { Application } from '@shared/models/application';
import { ApplicationEnrolment } from '@shared/models/applicationEnrolment';
import { ChangeOfEnrolment, COE_SUBMITTED_STATES } from '@shared/models/change-of-enrolment';
import { FullEnrolment, Enrolment } from '@shared/models/enrolment';
import {
  AcademicYearEnrolmentSummary,
  ArchivedEnrolmentSummary,
  SummaryAction,
} from '@shared/models/enrolment-summary';
import { Qualification } from '@shared/models/qualification';
import { TeachingPeriod } from '@shared/models/teaching-period';
import { ApplicationService } from '@shared/services/application/application.service';
import { ChangeOfEnrolmentService } from '@shared/services/change-of-enrolment/change-of-enrolment.service';
import { DSHttpError, UCErrorCodes } from '@shared/services/data-service';
import { EnrolmentService } from '@shared/services/enrolment/enrolment.service';
import { EnrolmentSummaryService } from '@shared/services/enrolment-summary/enrolment-summary.service';
import { FlashMessageService } from '@shared/services/flash-message/flash-message.service';
import {
  FullEnrolmentService,
  FULL_ENROLMENT_ERROR_CODES,
} from '@shared/services/full-enrolment/full-enrolment.service';
import { LoggingService, Logger } from '@shared/services/logging/logging.service';
import { showModalLoadingState, ModalService, IModalOptions } from '@shared/services/modal/modal.service';
import { ProcessService } from '@shared/services/process/process.service';
import { UserService } from '@shared/services/user/user.service';
import { UserActivityService } from '@shared/services/user-activity/user-activity.service';
import { WindowService } from '@shared/services/window/window.service';

@Component({
  selector: 'uc-application-overview',
  templateUrl: './application-overview.component.html',
  styleUrls: ['./application-overview.component.scss'],
})
export class ApplicationOverviewComponent implements OnInit, OnChanges {
  @Input() expanded = false;
  @Input() year: string;
  @Output() updateParams = new EventEmitter();
  @Output() updateViewData = new EventEmitter();

  log: Logger;
  compact = true;
  private working: boolean;
  isLoading = false;
  courseLoading = false;
  qualLoading = false;
  fullEnrolments: FullEnrolment[] = [];
  activeFullEnrolments: FullEnrolment[] = [];
  teachingPeriods: TeachingPeriod[];
  enrolments: Enrolment[] = [];
  baseStrings = strings.components.molecules.applicationYearListItemBaseStrings;
  addStrings = strings.components.organisms.enrolmentSelector;
  withdrawnEnrolments: string[] = [];
  showAllWithdrawn = false;
  currentStage = 1;
  enrolmentChange: ChangeOfEnrolment;
  noActiveEnrolments = false;
  creatingEnrolmentChange = false;
  isImpersonated = false;
  applicationSummary: AcademicYearEnrolmentSummary;
  archivedEnrolment: ArchivedEnrolmentSummary;
  isArchived = false;
  state: string;
  archivedQuals: Qualification[];
  canWithdraw = false;
  withdrawnApplicationEnrolments: string[] = [];
  courseState: string;
  isInternational: boolean;

  inProgressStates = [
    Application.STATE.DRAFT,
    Application.STATE.BEING_ASSESSED,
    Application.STATE.INCOMPLETE,
    Application.STATE.CONDITIONAL_OFFER,
    Application.STATE.OFFER_OF_PLACE,
    Application.STATE.OFFER_ACCEPTED,
    Application.STATE.OFFER_DECLINED,
    Application.STATE.OFFER_DEFERRED,
    Application.STATE.PROGRESS,
    Application.STATE.OFFER_OF_PLACE_ACCEPT_DECLINE,
  ];

  constructor(
    private ws: WindowService,
    private router: Router,
    private userService: UserService,
    private fullEnrolment: FullEnrolmentService,
    private enrolmentService: EnrolmentService,
    private processService: ProcessService,
    private applicationService: ApplicationService,
    private flashMessageService: FlashMessageService,
    private modalService: ModalService,
    private userActivityService: UserActivityService,
    private enrolmentSummaryService: EnrolmentSummaryService,
    private changeOfEnrolmentService: ChangeOfEnrolmentService,
    loggingService: LoggingService,
  ) {
    this.log = loggingService.createLogger(this);
  }

  get primaryAction(): SummaryAction {
    return get(this, 'applicationSummary.actions.primary', null);
  }

  get secondaryActions(): SummaryAction[] {
    return get(this, 'applicationSummary.actions.secondary', []);
  }

  get configForActionButtons(): SummaryAction[] {
    const actionsToFilterOut = [APPLICATION_ACTIONS.COE_CANNOT_START];
    return this.secondaryActions.filter((sa) => actionsToFilterOut.indexOf(sa.action) < 0);
  }

  get appSummary(): AcademicYearEnrolmentSummary {
    return get(this, 'applicationSummary', new AcademicYearEnrolmentSummary({}));
  }

  get changeQualAction() {
    return this.secondaryActions.find((sa) => sa.action === APPLICATION_ACTIONS.CHANGE_QUALIFICATION);
  }

  get changeCourseAction() {
    return this.secondaryActions.find((sa) => sa.action === APPLICATION_ACTIONS.CHANGE_COURSE);
  }

  get applicationInProgressState() {
    return this.inProgressStates.indexOf(this.appSummary.state) >= 0;
  }

  get shouldShowWithdrawn() {
    return this.withdrawnEnrolments.length && !this.isArchived;
  }

  get allowExpansion() {
    const hasQualifications = this.fullEnrolments?.filter((fe) => !!fe.qualifications).length > 0;
    const archivedWithQuals = this.isArchived && this.archivedQuals && this.archivedQuals.length > 0;
    const hasSecondaryActions = this.appSummary.actions?.secondary?.length > 0;
    return archivedWithQuals || hasQualifications || hasSecondaryActions;
  }

  get isRelevantEnrolmentChange() {
    return this.enrolmentChange && this.enrolmentChange?.academicYear?.code === this.appSummary.academicYear;
  }

  get shouldShowActionButtons() {
    const isOfferProcess = this.appSummary?.state === APPLICATION_STATE.OFFER_IN_PROGRESS;
    const isDeferProcess = this.appSummary?.offerDecision?.state === OFFER_DECISION_STATE.DRAFT_DEFER;
    return !isOfferProcess && !isDeferProcess && !this.isRelevantEnrolmentChange && !this.isArchived;
  }

  get hideOfferStatusModal() {
    const hideStates = [APPLICATION_STATE.OFFER_IN_PROGRESS, APPLICATION_STATE.DEFER_OFFER_IN_PROGRESS];
    return !!hideStates.find((el) => el === this.appSummary?.state);
  }

  get hideManageEnrolmentView() {
    const hasCoa = !!get(this.appSummary, 'changeOfApplication');
    const coeInSubmitted =
      this.isRelevantEnrolmentChange && COE_SUBMITTED_STATES.includes(this.enrolmentChange?.state.code);
    return hasCoa || coeInSubmitted;
  }

  get isConcurrent() {
    return this.enrolments && this.enrolments.some((e) => e.enrolledQualifications.length > 1);
  }

  isDeleteAction(secondaryAction: SummaryAction) {
    const deleteIcons = [APPLICATION_ACTIONS.WITHDRAW, APPLICATION_ACTIONS.WITHDRAW_NOT_ALLOWED];
    return deleteIcons.indexOf(secondaryAction.action) >= 0;
  }

  secondaryActionIcon(secondaryAction: SummaryAction) {
    return this.isDeleteAction(secondaryAction) ? 'trash' : 'pencil';
  }

  ngOnInit() {
    this.changeOfEnrolmentService.enrolmentChange.subscribe((coe) => {
      this.enrolmentChange = coe;
    });

    this.userService.userDetail.subscribe((detail) => {
      this.isImpersonated = detail && !!detail.impersonated;
    });

    this.processService.isInternational().subscribe((isInternational) => {
      this.isInternational = isInternational;
    });

    this.getOverviewData();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.expanded && changes.expanded.currentValue && changes.expanded.firstChange) {
      this.compact = !changes.expanded;
    }
  }

  getOverviewData() {
    this.enrolmentSummaryService.summary$
      .pipe(
        filter((s) => !!s),
        switchMap((summary) => {
          this.applicationSummary = summary.academicYears.find((ay) => ay.academicYear === this.year);
          this.archivedEnrolment = summary.archivedEnrolments.find((ae) => ae.academicYear === this.year);
          this.isArchived = !!this.archivedEnrolment;
          this.state = this.isArchived ? this.archivedEnrolment.state : this.applicationSummary.state;
          if (get(this.applicationSummary, 'changeOfApplication')) {
            this.state = APPLICATION_CHANGE_STATE.CHANGE_IN_PROGRESS;
          }

          if (this.applicationSummary?.offerDecision?.state === OFFER_DECISION_STATE.DRAFT_DEFER) {
            this.state = OFFER_DECISION_STATE.DRAFT_DEFER;
          }

          this.courseState = get(this.applicationSummary, 'courseSelectionState');
          // If this is displaying data on an archived enrolment, we only need to get any qual data to display
          const archivedEnrolledQuals = get(this.archivedEnrolment, 'enrolledQualifications');
          if (this.isArchived) {
            if (archivedEnrolledQuals && archivedEnrolledQuals.length > 0) {
              return this.fullEnrolment
                .getArchivedQualData(this.year, archivedEnrolledQuals)
                .pipe(filter((s) => !!s))
                .pipe(
                  map((archivedQuals) => {
                    this.archivedQuals = archivedQuals.qualification;
                  }),
                );
            } else {
              return EMPTY;
            }
          } else if (this.appSummary.latestSummaryType === SUMMARY_TYPES.APPLICATION) {
            // If the application has no applicationEnrolment data, then we have enough to display at this point
            return EMPTY;
          } else if (this.appSummary.latestSummaryType === SUMMARY_TYPES.APPLICATION_ENROLMENTS) {
            return this.getApplicationEnrolmentViewData();
          } else {
            return this.getEnrolmentViewData();
          }
        }),
      )
      .pipe(filter((resp) => !!resp))
      .subscribe();
  }

  updateUrlParams(data: { [key in 'action' | 'year']: string }): void {
    this.updateParams.emit(data);
  }

  toggleCompactView(): void {
    const action = this.compact ? 'add' : 'remove';
    if (this.allowExpansion) {
      this.updateUrlParams({ action, year: this.year });
      this.compact = !this.compact;
    }
  }

  getEnrolmentViewData() {
    return this.enrolmentService
      .getEnrolment(this.year)
      .pipe(filter((s) => !!s))
      .pipe(
        concatMap((enrolment) => {
          return this.getEnrolledQualData([enrolment], enrolment.enrolledQualifications);
        }),
      );
  }

  getApplicationEnrolmentViewData() {
    return this.applicationService.getApplicationEnrolment(this.year).pipe(
      concatMap((aes: ApplicationEnrolment[]) => {
        const enrolledQuals = flattenDeep(aes.map((e) => e.enrolledQualifications));
        this.canWithdraw = !!aes.find((ae) => ae.canWithdraw);
        const withdrawnAEs = aes.filter((ae) => ae.state.code === 'withdrawn');
        this.withdrawnApplicationEnrolments = withdrawnAEs.map((ae) => ae.internalReference);
        return this.getEnrolledQualData(aes, enrolledQuals);
      }),
    );
  }

  getEnrolledQualData(enrolments, enrolledQualifications) {
    const { academicYear, processName } = this.appSummary;

    return this.fullEnrolment.getEnrolledQualData(academicYear, enrolledQualifications).pipe(
      filter((s) => !!s),
      switchMap(([qualifications, courseOccurrences, teachingPeriods]) => {
        this.teachingPeriods = teachingPeriods;

        this.fullEnrolments = FullEnrolment.createFrom(enrolments, qualifications.qualification, courseOccurrences);

        if (this.fullEnrolments.some((el) => !el.priority)) {
          this.fullEnrolments = this.fullEnrolments.sort((a, b) => a.displayOrder - b.displayOrder);
        } else {
          this.fullEnrolments = this.fullEnrolments.sort((a, b) => a.priority - b.priority);
        }

        this.withdrawnEnrolments = this.formatWithdrawnEnrolments(this.fullEnrolments);
        this.activeFullEnrolments = this.fullEnrolments.filter((fe) => fe.state.code !== 'withdrawn');

        this.setActiveEnrolmentStatus();
        if (this.applicationInProgressState) {
          return this.processService.getStageSummary(processName, academicYear).pipe(
            map(
              (stageData) => {
                this.currentStage = stageData.currentStage;
              },
              (err) => {
                if (err.code !== UCErrorCodes.E404) {
                  throw err;
                }
              },
            ),
          );
        } else {
          return EMPTY;
        }
      }),
      catchError((err) => {
        const knownError = err.code === FULL_ENROLMENT_ERROR_CODES.noEnrolments;
        if (knownError) {
          this.log.error(err);
          this.setActiveEnrolmentStatus();
        }
        return err;
      }),
    );
  }

  setActiveEnrolmentStatus(): void {
    const allWithdrawn = this.appSummary.state === APPLICATION_STATE.WITHDRAWN;
    const declinedAndNoActiveEnrolments =
      this.appSummary.state === APPLICATION_STATE.AGREEMENT_DECLINED && !this.fullEnrolments.length;
    this.noActiveEnrolments = allWithdrawn || declinedAndNoActiveEnrolments;
  }

  formatWithdrawnEnrolments(fullEnrolments: FullEnrolment[]) {
    const withdrawnEnrolments = [];
    fullEnrolments
      .filter((fe) => get(fe.state, 'code') === ENROLMENT_STATE.WITHDRAWN)
      .map((fe) => {
        const qualTitles = fe.qualifications.map((q) => q.qualificationAnswer.title);
        withdrawnEnrolments.push(`${this.appSummary.academicYear} ${qualTitles.join(' / ')}`);
      });

    return withdrawnEnrolments;
  }

  primaryActionHandler(): Promise<boolean> {
    this.isLoading = true;
    return new Promise((resolve) => {
      if (this.working) {
        resolve(true);
        return;
      }
      this.working = true;
      showModalLoadingState();

      const { academicYear, processName, actions } = this.appSummary;
      const actionUrl = actions.primary.href && get(externalUrls, actions.primary.href);
      if (actionUrl) {
        const { action } = actions.primary;

        const finalHref = action === APPLICATION_ACTIONS.CHANGE ? `${actionUrl}&YEAR=${academicYear}` : actionUrl;

        this.refreshTokenAndNavigate(finalHref);
        resolve(true);
      } else if (actions.primary.action === APPLICATION_ACTIONS.OFFER_AVAILABLE) {
        this.triggerEvaluateProcess(PROCESS_NAMES.OFFER_DECISION);
        this.navInternal(internalUrls.processPage(PROCESS_NAMES.OFFER_DECISION, academicYear));
      } else {
        this.navInternal(internalUrls.processPage(processName, academicYear));
      }
    });
  }

  triggerActionFromStatusPill(secondaryActionCode?: string) {
    if (!secondaryActionCode) {
      this.primaryActionHandler();
    } else {
      const secondaryAction = this.secondaryActions.find((el) => el.action === secondaryActionCode);
      this.handleAction(secondaryAction);
    }
  }

  stopWorking() {
    this.working = false;
  }

  createModalOptions(handler, modalText): IModalOptions {
    const baseOptions = { closeHandler: this.boundModalHandler('stopWorking') };
    return Object.assign(baseOptions, handler, modalText);
  }

  handleAction(secondaryAction: SummaryAction) {
    const { action } = secondaryAction;
    this[action](secondaryAction);
  }

  [APPLICATION_ACTIONS.CHANGE_COURSE](secondaryAction: SummaryAction) {
    const { state, academicYear } = this.appSummary;
    const { urlPath } = secondaryAction;
    const hasHref = !!secondaryAction.href;
    const modalKeys = {
      [Application.STATE.FULLY_ENROLLED]: this.baseStrings.changeOfEnrolmentCourse(),
      [Application.STATE.AGREEMENT_DECLINED]: this.baseStrings.declinedCourseUCSW(academicYear),
      [Application.STATE.WITHDRAWN]: this.baseStrings.withdrawnCourseUCSW(academicYear),
    };
    this.inProgressStates.forEach(
      (appState) => (modalKeys[appState] = this.baseStrings.courseChange(this.currentStage)),
    );
    const modalText = modalKeys[state];

    if (!modalText) {
      return this.log.error(`No modalText found for CHANGE_COURSE action in state: ${state}`);
    }
    const inProgressNavInternal = this.applicationInProgressState && !hasHref;
    const withdrawnNavInternal = state === Application.STATE.WITHDRAWN && !hasHref;
    if (inProgressNavInternal || withdrawnNavInternal) {
      return this.modalService.showModal(
        this.createModalOptions({ handler: this.boundModalHandler('changeQualOrCourses') }, modalText),
      );
    }

    this.modalService.showModal(
      this.createModalOptions({ handler: this.boundModalHandler('refreshTokenAndNavigate', urlPath) }, modalText),
    );
  }

  boundModalHandler(key: string, options?: unknown) {
    return options ? this[key].bind(this, options) : this[key].bind(this);
  }

  [APPLICATION_ACTIONS.CHANGE_QUALIFICATION]() {
    const { state, academicYear } = this.appSummary;
    const isInFinalStage =
      this.currentStage === 3 || this.appSummary.state === Application.STATE.OFFER_OF_PLACE_ACCEPT_DECLINE;
    const modalKeys = {
      [Application.STATE.FULLY_ENROLLED]: this.baseStrings.changeOfEnrolment,
      [Application.STATE.AGREEMENT_DECLINED]: this.baseStrings.declinedQualUCSW(academicYear),
      [Application.STATE.WITHDRAWN]: this.baseStrings.withdrawnQualUCSW(academicYear),
    };
    this.inProgressStates.forEach((appState) => (modalKeys[appState] = this.baseStrings.qualChange(isInFinalStage)));
    const modalText = modalKeys[state];

    if (!modalText) {
      return this.log.error(`No modalText found for CHANGE_QUALIFICATION action in state: ${state}`);
    }

    const inProgressNavInternal = this.applicationInProgressState && !isInFinalStage;
    if (inProgressNavInternal) {
      return this.modalService.showModal(
        this.createModalOptions({ handler: this.boundModalHandler('changeQualOrCourses') }, modalText),
      );
    }

    this.modalService.showModal(
      this.createModalOptions({ handler: this.boundModalHandler('startChangeOfApplication') }, modalText),
    );
  }

  [APPLICATION_ACTIONS.CHANGE_QUALIFICATION_NOT_ALLOWED]() {
    let modalText = this.baseStrings.changeNotAllowed(this.baseStrings.qualification);
    if (this.appSummary.state === Application.STATE.AGREEMENT_AVAILABLE) {
      modalText = this.baseStrings.changeNotAllowedOffer(this.baseStrings.qualification);
    }
    if (this.appSummary.state === Application.STATE.DEFERRAL_REQUESTED) {
      modalText = this.baseStrings.changeNotAllowedDeferralRequested(this.baseStrings.qualificationOrSubjects);
    }
    this.modalService.showAlertModal(modalText);
  }

  [APPLICATION_ACTIONS.CHANGE_COURSE_NOT_ALLOWED]() {
    let modalText = this.baseStrings.changeNotAllowed(this.baseStrings.courses);
    if (this.appSummary.state === Application.STATE.AGREEMENT_AVAILABLE) {
      modalText = this.baseStrings.changeNotAllowedOffer(this.baseStrings.courses);
    }
    if (this.appSummary.state === Application.STATE.DEFERRAL_REQUESTED) {
      modalText = this.baseStrings.changeNotAllowedDeferralRequested(this.baseStrings.courses);
    }
    this.modalService.showAlertModal(modalText);
  }

  [APPLICATION_ACTIONS.CHANGE_COURSE_SUBMITTED]() {
    return this.changeQualOrCourses();
  }

  [APPLICATION_ACTIONS.CHANGE_QUALIFICATION_SUBMITTED](secondaryAction: SummaryAction) {
    return this.refreshTokenAndNavigate(secondaryAction.urlPath);
  }

  [APPLICATION_ACTIONS.COE_CAN_START]() {
    const modalText = isUcoStudent() ? this.baseStrings.changeOfEnrolmentUco : this.baseStrings.changeOfEnrolment;
    const modalConfig = this.createModalOptions(
      { handler: this.boundModalHandler('startChangeOfEnrolment') },
      modalText,
    );
    this.modalService.showModal(modalConfig);
  }

  [APPLICATION_ACTIONS.WITHDRAW]() {
    const modalText = this.baseStrings.withdrawApplication;
    const modalConfig = this.createModalOptions({ handler: this.boundModalHandler('withdrawApplication') }, modalText);
    this.modalService.showModal(modalConfig);
  }

  [APPLICATION_ACTIONS.WITHDRAW_NOT_ALLOWED]() {
    const modalText = this.baseStrings.cannotWithdrawApplication;
    this.modalService.showAlertModal(modalText);
  }

  [APPLICATION_ACTIONS.COA_CANNOT_START]() {
    const modalText = this.baseStrings.coaChangeNotAllowed;
    this.modalService.showAlertModal(modalText);
  }

  [APPLICATION_ACTIONS.QUALIFICATION_NO_STUDENT_ID]() {
    const modalText = this.baseStrings.qualNoStudentId;
    this.modalService.showAlertModal(modalText);
  }

  [APPLICATION_ACTIONS.CHANGE_QUALIFICATION_NCEA_EMBARGO]() {
    const modalText = this.baseStrings.nceaNotAllowed('qualification');
    this.modalService.showAlertModal(modalText);
  }

  [APPLICATION_ACTIONS.CHANGE_COURSE_NCEA_EMBARGO]() {
    const modalText = this.baseStrings.nceaNotAllowed('courses');
    this.modalService.showAlertModal(modalText);
  }

  [APPLICATION_ACTIONS.DEFER_OFFER]() {
    this.triggerEvaluateProcess(PROCESS_NAMES.DEFER_OFFER);
    this.navInternal(internalUrls.processPage(PROCESS_NAMES.DEFER_OFFER, this.applicationSummary.academicYear));
  }

  withdrawApplication() {
    const success = async () => {
      this.flashMessageService.pushSuccess(this.baseStrings.successMessage, { countdown: 20 });
      this.updateViewData.emit();
      await this.userActivityService.removeAllTasksFor(
        this.applicationSummary.processName,
        this.applicationSummary.academicYear,
      );
    };

    const error = (err: DSHttpError) => {
      if (err.code === UCErrorCodes.E422) {
        const modalText = this.baseStrings.withdrawApplicationProblem;
        this.modalService.showAlertModal(modalText);
      }
    };

    this.applicationService.deleteApplication(this.applicationSummary.academicYear).subscribe(success, error);
  }

  triggerEvaluateProcess(processName: string) {
    this.processService.evaluateProcess(processName, this.applicationSummary.academicYear).subscribe();
  }

  refreshTokenAndNavigate(url): Promise<boolean> {
    return this.userService
      .refreshTokenBeforeNavigate()
      .toPromise()
      .then(() => {
        this.navExternal(url);
        return Promise.resolve(true);
      })
      .catch(() => {
        return Promise.resolve(true);
      });
  }

  navExternal = (destination: string) => {
    this.ws.nativeWindow.location.href = destination;
  };

  navInternal = (destination: string[]) => {
    return this.router.navigate(destination);
  };

  withdrawEnrolment() {
    this.getOverviewData();
  }

  changeQualOrCourses(): Promise<boolean> {
    if (this.working) {
      return Promise.resolve(true);
    }
    this.working = true;
    const { processName, academicYear } = this.appSummary;
    return this.navInternal(internalUrls.processPage(processName, academicYear));
  }

  startChangeOfApplication() {
    this.processService.createChangeOfApplication(this.year).subscribe(() => {
      this.navInternal(internalUrls.processPage(PROCESS_NAMES.COA, this.appSummary.academicYear));
    });
  }

  async startChangeOfEnrolment() {
    await this.userActivityService.removeAllTasksFor(PROCESS_NAMES.COE, this.appSummary.academicYear);
    if (!this.enrolmentChange) {
      this.enrolmentChange = new ChangeOfEnrolment({ tainted: false });
    }
    this.processService.createChangeOfEnrolment(this.year, this.enrolmentChange).subscribe(() => {
      this.navInternal(internalUrls.processPage(PROCESS_NAMES.COE, this.appSummary.academicYear));
    });
  }

  cancelInProgressProcess() {
    this.updateViewData.emit();
  }
}
