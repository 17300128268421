/* stylelint-disable value-keyword-case */

/* 2024-09-10 KY: Disabled above rule for this file. */

@import "../0-utilities/utilities";

// 1. Global
// ---------

$contrast-debug: false;
$contrast-warnings: false;
$global-font-size: 100%;
$global-width: uc-rem-convert(1200);
$global-lineheight: 1.4;

@import "colors";

$colors: (
  "monza": #cc0011,
  "primary": #cc0011,
  "blue-ribbon": #066ce8,
  "jade": #00aa6e,
  "amber": #ffc000,
  "mine-shaft": #292929,
  "black": #292929,
  "abbey": #45494c,
  "rolling-stone": #727779,
  "oslo-grey": #929598,
  "dusty-grey": #979797,
  "silver-sand": #c4c7ca,
  "mercury": #e1e1e1,
  "concrete": #f3f3f3,
  "white": #ffffff,
  "facebook": #5d73af,
  "google": #dd4b39,
  "emerald": #46c078,
  "bondi-blue": #00aab4,
  "fire-engine-red": #d7252c,
  "vermilion": #ff4900,
  "indigo": #46126d,
  "frenchRose": #f2478c,
  "turquoise": #00f9cf,
  "errorRed": #ee0004,
  "errorBackgroundRed": #fff9f7,
);
$white: color("white");
$black: color("black");

@import "fonts";

$body-font-family: $primaryFontFamily;
$global-margin: uc-rem-convert(24);
$global-padding: uc-rem-convert(24);
$global-weight-normal: font("primary", "regular");
$global-weight-bold: font("primary", "bold");
$global-radius: uc-rem-convert(3);
$global-menu-padding: uc-rem-convert(16);
$global-menu-nested-margin: uc-rem-convert(16);
$global-text-direction: ltr;
$global-button-cursor: pointer;
$global-text-direction: ltr;
$global-left: if($global-text-direction == rtl, right, left);
$global-right: if($global-text-direction == rtl, left, right);
$global-color-pick-contrast-tolerance: 0;
$print-transparent-backgrounds: true;
$body-background: color("white");
$body-font-color: color("black");

// 2. Breakpoints
// --------------

$breakpoints: (
  tny: 0,
  sml: 320px,
  med: 640px,
  lrg: 1024px,
  hug: 1200px,
  mas: 1440px,
);
$print-breakpoint: lrg;

// 3. The grid
// -----------

$grid-container: $global-width;
$grid-columns: 12;
$grid-margin-gutters: uc-rem-convert(20);
$grid-padding-gutters: $grid-margin-gutters;
$grid-container-padding: $grid-padding-gutters;
$grid-container-max: $global-width;
$block-grid-max: 8;

// 4. Base Typography
// ------------------

$header-font-family: $body-font-family;
$header-font-weight: font("primary", "bold");
$header-font-style: normal;
$font-family-monospace: Consolas, "Liberation Mono", Courier, monospace;
$header-color: inherit;
$header-lineheight: 1.4;
$header-margin-bottom: uc-rem-convert(5);
$header-styles: (
  tny: (
    "h1": (
      "font-size": 42,
      "font-weight": font("primary", "regular"),
    ),
    "h2": (
      "font-size": 36,
    ),
    "h3": (
      "font-size": 32,
    ),
    "h4": (
      "font-size": 24,
    ),
    "h5": (
      "font-size": 20,
    ),
    "h6": (
      "font-size": 18,
    ),
  ),
);
$header-text-rendering: optimizeLegibility;
$small-font-size: 80%;
$paragraph-lineheight: 1.6;
$paragraph-margin-bottom: uc-rem-convert(20);
$paragraph-text-rendering: optimizeLegibility;
$anchor-color: color("blue-ribbon");
$anchor-color-hover: scale-color($anchor-color, $lightness: -14%);
$anchor-text-decoration: underline;
$anchor-text-decoration-hover: underline;
$hr-width: $global-width;
$hr-border: 1px solid color("oslo-grey");
$hr-margin: uc-rem-convert(20) auto;
$list-lineheight: $paragraph-lineheight;
$list-margin-bottom: $paragraph-margin-bottom;
$list-style-type: disc;
$list-style-position: outside;
$list-side-margin: 0;
$list-nested-side-margin: uc-rem-convert(25);
$defnlist-margin-bottom: uc-rem-convert(20);
$defnlist-term-weight: $global-weight-bold;
$defnlist-term-margin-bottom: uc-rem-convert(3);
$blockquote-color: color("rolling-stone");
$blockquote-padding: uc-rem-convert(9 20 0 19);
$blockquote-border: 1px solid color("oslo-grey");
$cite-font-size: uc-rem-convert(13);
$cite-color: color("rolling-stone");
$cite-pseudo-content: "\2014 \0020";
$keystroke-font: $font-family-monospace;
$keystroke-color: color("black");
$keystroke-background: color("mercury");
$keystroke-padding: uc-rem-convert(2 4 0);
$keystroke-radius: $global-radius;
$abbr-underline: 1px dotted color("black");
$body-font-color: color("black");

// 5. Typography Helpers
// ---------------------

$lead-font-size: $global-font-size * 1.25;
$lead-lineheight: 1.6;
$subheader-lineheight: 1.4;
$subheader-color: color("rolling-stone");
$subheader-font-weight: $global-weight-normal;
$subheader-margin-top: uc-rem-convert(2);
$subheader-margin-bottom: uc-rem-convert(5);
$stat-font-size: uc-rem-convert(25);

// 6. Badge
// --------

$badge-background: color("primary");
$badge-color: color("white");
$badge-color-alt: color("black");
$badge-palette: $colors;
$badge-padding: 0.3em;
$badge-minwidth: 2.1em;
$badge-font-size: uc-rem-convert(6);

// 7. Breadcrumbs
// ---------------

$breadcrumbs-margin: 0 0 $global-margin 0;
$breadcrumbs-item-font-size: uc-rem-convert(11);
$breadcrumbs-item-color: color("primary");
$breadcrumbs-item-color-current: color("black");
$breadcrumbs-item-color-disabled: color("oslo-grey");
$breadcrumbs-item-margin: uc-rem-convert(75);
$breadcrumbs-item-uppercase: true;
$breadcrumbs-item-separator: true;
$breadcrumbs-item-separator-item: "/";
$breadcrumbs-item-separator-item-rtl: "\\";
$breadcrumbs-item-separator-color: color("oslo-grey");

// // 8. Button
// // ----------

$defaultBgColor: $colorMonza;
$defaultHoverBgColor: $colorAbbey;
$defaultTextColor: $colorWhite;
$secondaryBgColor: $colorAbbey;
$secondaryHoverBgColor: $colorMonza;
$disabledBgColor: $colorMercury;
$facebookBgColor: $colorWaikawaGray;
$googleBgColor: $colorPunch;
$button-font-family: inherit;
$button-text-transform: uppercase;
$button-weight: font("primary", "bold");
$button-padding: 1.25em 2em;
$button-margin: 0 0 $global-margin 0;
$button-background: color("monza");
$button-background-hover: color("abbey");
$button-color: color("white");
$button-radius: $global-radius;
$button-font-sizes: (
  tny: uc-rem-convert(9),
  sml: uc-rem-convert(10),
  default: uc-rem-convert(12),
  lrg: uc-rem-convert(14),
);
$button-padding-sizes: (
  tny: 0.9em 1.8em,
  sml: 1.1em 1.8em,
  default: $button-padding,
  lrg: 1.3em 2.5em,
);
$button-types: (
  "secondary": (
    "background": color("abbey"),
    "color": color("white"),
    "background-hover": color("monza"),
    "radius": uc-rem-convert(100),
    "weight": font("primary", "semibold"),
    "transform": none,
  ),
  "success": color("jade"),
  "warning": color("amber"),
  "facebook": (
    "background": color("facebook"),
    "color": color("white"),
    "radius": uc-rem-convert(100),
    "transform": none,
  ),
  "google": (
    "background": color("google"),
    "color": color("white"),
    "radius": uc-rem-convert(100),
    "transform": none,
  ),
);
$button-opacity-disabled: 0.25;
$button-background-hover-lightness: -20%;
$button-transition:
  background-color 0.25s ease-out,
  color 0.25s ease-out;

// 9. Buttons
// ----------------

$buttons-gutter: uc-rem-convert(5);

// 10. Forms
// ---------

$legend-padding: uc-rem-convert(0 3);
$form-spacing: uc-rem-convert(16);
$helptext-color: color("black");
$helptext-font-size: uc-rem-convert(13);
$helptext-font-style: italic;
$input-prefix-color: color("black");
$input-prefix-background: color("mercury");
$input-prefix-border: 1px solid color("oslo-grey");
$form-label-color: color("black");
$form-label-font-size: uc-rem-convert(14);
$form-label-font-weight: $global-weight-normal;
$form-label-line-height: 1.8;
$select-background: color("white");
$select-triangle-color: color("rolling-stone");
$select-radius: 0;
$input-color: color("black");
$input-placeholder-color: color("oslo-grey");
$input-font-family: inherit;
$input-font-size: uc-rem-convert(16);
$input-font-weight: $global-weight-normal;
$input-line-height: $global-lineheight;
$input-background: color("white");
$input-background-focus: color("white");
$input-background-disabled: color("mercury");
$input-border: 1px solid color("oslo-grey");
$input-border-focus: 1px solid color("rolling-stone");
$input-padding: $form-spacing * 0.5;
$input-shadow: inset 0 1px 2px rgba(color("black"), 0.1);
$input-shadow-focus: 0 0 5px color("oslo-grey");
$input-cursor-disabled: not-allowed;
$input-transition:
  box-shadow 0.5s,
  border-color 0.25s ease-in-out;
$input-number-spinners: true;
$input-radius: $global-radius;
$form-button-radius: $global-radius;

// 11. Menu
// --------

$menu-margin: 0;
$menu-nested-margin: $global-menu-nested-margin;
$menu-items-padding: $global-menu-padding;
$menu-simple-margin: uc-rem-convert(10);
$menu-item-color-active: color("white");
$menu-item-background-active: get-color(primary);
$menu-icon-spacing: uc-rem-convert(25);
$menu-state-back-compat: true;
$menu-centered-back-compat: true;
$menu-icons-back-compat: true;

// 12. Pagination
// --------------

$pagination-font-size: uc-rem-convert(14);
$pagination-margin-bottom: $global-margin;
$pagination-item-color: color("black");
$pagination-item-padding: uc-rem-convert(3 10);
$pagination-item-spacing: uc-rem-convert(10);
$pagination-radius: $global-radius;
$pagination-item-background-hover: color("mercury");
$pagination-item-background-current: color("primary");
$pagination-item-color-current: color("white");
$pagination-item-color-disabled: color("oslo-grey");
$pagination-ellipsis-color: color("black");
$pagination-mobile-items: false;
$pagination-mobile-current-item: false;
$pagination-arrows: true;

// 13. Table
// ---------

$table-background: color("white");
$table-color-scale: 5%;
$table-border: 1px solid color-scale($table-background, $table-color-scale);
$table-padding: uc-rem-convert(8 10 10);
$table-hover-scale: 2%;
$table-row-hover: darken($table-background, $table-hover-scale);
$table-row-stripe-hover: darken($table-background, $table-color-scale + $table-hover-scale);
$table-is-striped: false;
$table-striped-background: color-scale($table-background, $table-color-scale);
$table-stripe: even;
$table-head-background: color("white");
$table-head-row-hover: darken($table-head-background, $table-hover-scale);
$table-foot-background: color-scale($table-background, $table-color-scale);
$table-foot-row-hover: darken($table-foot-background, $table-hover-scale);
$table-head-font-color: $body-font-color;
$table-head-text-transform: uppercase;
$table-foot-font-color: $body-font-color;
$show-header-for-stacked: false;
$table-stack-breakpoint: med;

// 14. Tabs
// --------

$tab-margin: 0;
$tab-background: color("white");
$tab-color: color("primary");
$tab-background-active: color("mercury");
$tab-active-color: color("primary");
$tab-item-font-size: uc-rem-convert(12);
$tab-item-background-hover: color("white");
$tab-item-padding: 1em 1.8em;
$tab-content-background: color("white");
$tab-content-border: color("mercury");
$tab-content-color: $body-font-color;
$tab-content-padding: uc-rem-convert(10);
