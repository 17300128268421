import { camelizeKeys } from '@shared/helpers/serialization';

export const PAYMENT_SERVICE_PATH = '/process/payment';

interface PaymentLinkResponse {
  link: string;
  manifest: CourseInfoResponse[];
}

// Disable naming convention rule for this interface as it is what
// the backend returns.
/* eslint-disable @typescript-eslint/naming-convention */
interface CourseInfoResponse {
  course_code: string;
  occurrence: string;
}

// Disable naming convention rule for this interface as it is what
// the backend returns.
/* eslint-disable @typescript-eslint/naming-convention */
interface PaymentResponse {
  payment: PaymentLinkResponse;
  error: string;
  next_action: string;
}

export class CourseInfo {
  courseCode: string;
  occurrence: string;

  constructor(obj) {
    Object.assign(this, obj);
  }

  public static deserialize(obj): CourseInfo {
    const payload = obj;
    return new CourseInfo(camelizeKeys(payload));
  }

  public static deserializeAll(obj): CourseInfo[] {
    const payload = obj.manifest || obj;
    return payload.map((e) => {
      return new CourseInfo(camelizeKeys(e));
    });
  }
}

export class Payment {
  link: string;
  manifest: CourseInfo[];
  nextAction: string;
  error: string;

  constructor(obj) {
    Object.assign(this, obj);
  }

  public static deserialize(obj: PaymentResponse): Payment {
    const { payment } = obj;
    const outerPayload = obj;

    const serPayload = {
      ...payment,
      error: outerPayload.error,
      nextAction: outerPayload.next_action,
    };

    const camelized = camelizeKeys(serPayload);
    camelized.manifest = camelized?.manifest ? CourseInfo.deserializeAll(camelized.manifest) : [];
    return new Payment(camelizeKeys(camelized));
  }
}

export class PaymentRefundIntent {
  amount: number;
  amountReceived: number;
  courseCode: string;
  created: number;
  customer: string;
  id: string;
  occurrenceCode: string;
  receiptEmail: string;
  status: string;

  constructor(obj) {
    Object.assign(this, obj);
  }

  public static deserialize(obj): PaymentRefundIntent {
    const payload = obj.paymentRefund || obj;
    const camelized = camelizeKeys(payload);
    return new PaymentRefundIntent(camelized);
  }
}
export class PaymentRefundCharge {
  amount: number;
  amountCaptured: number;
  amountRefunded: number;
  id: string;
  networkOutcome: string;
  paid: boolean;
  receiptUrl: string;
  refunded: boolean;
  sellerOutcome: string;

  constructor(obj) {
    Object.assign(this, obj);
  }

  public static deserialize(obj): PaymentRefundCharge {
    const payload = obj.paymentRefund || obj;
    const camelized = camelizeKeys(payload);
    return new PaymentRefundCharge(camelized);
  }
}

export class PaymentRefundResponse {
  charge: PaymentRefundCharge;
  intent: PaymentRefundIntent;
  manifest: [];
  message?: string;

  constructor(obj) {
    Object.assign(this, obj);
  }

  public static deserialize(obj): PaymentRefundResponse {
    const payload = obj.paymentRefund || obj;
    const camelized = camelizeKeys(payload);
    camelized.charge = camelized?.charge ? PaymentRefundCharge.deserialize(camelized.charge) : [];
    camelized.intent = camelized?.intent ? PaymentRefundIntent.deserialize(camelized.intent) : [];
    return new PaymentRefundResponse(camelized);
  }
}

export class PaymentRefundCheckResponse {
  amountRefundable: number;

  constructor(obj) {
    Object.assign(this, obj);
  }

  public static deserialize(obj): PaymentRefundCheckResponse {
    const payload = obj.paymentRefundCheck || obj;
    const camelized = camelizeKeys(payload);
    return new PaymentRefundCheckResponse(camelized);
  }
}

export class RefundSuccess {
  description: string;

  constructor(obj) {
    Object.assign(this, obj);
  }

  public static deserialize(obj): RefundSuccess {
    const payload = obj;
    return new RefundSuccess(camelizeKeys(payload));
  }
}

export class CustomerId {
  customerId: string;

  constructor(obj) {
    Object.assign(this, obj);
  }

  public static deserialize(obj): CustomerId {
    const payload = obj;
    return new CustomerId(camelizeKeys(payload));
  }
}
