import { CHANGE_ACTIONS, COE_STATES } from '@shared/constants/states.constants';
import { camelizeKeys, deepSnakeify, snakeifyKeys } from '@shared/helpers/serialization';

import { AssociatedPerson } from './associated-person';
import { BackgroundCheck } from './background-check';
import { Condition } from './condition';
import { CourseOccurrence } from './course';
import { EnrolmentError } from './enrolment-error';
import { InterviewAssessment } from './interview-assessment';
import { OnlineAssessment } from './online-assessment';
import { PoliceCheck } from './policeCheck';
import { PostgradIntentions } from './postgrad-intentions';
import { Qualification, EnrolledQualification } from './qualification';
import { ReferenceData } from './reference-data';
import { TeachingAssessment } from './teaching-assessment';

export const COE_SUBMITTED_STATES = [COE_STATES.SUBMITTED, COE_STATES.UNCERTAIN];

export const ENROLMENT_STATE_COLOURS = {
  [COE_STATES.SUBMITTED]: 'green',
  [COE_STATES.UNCERTAIN]: 'green',
  [COE_STATES.APPROVED]: 'yellow',
  [COE_STATES.IN_PROGRESS]: 'yellow',
  [COE_STATES.WITHDRAWN]: 'yellow',
  [COE_STATES.UNREADY]: 'yellow',
  [COE_STATES.COMPLETE]: 'yellow',
  [COE_STATES.DRAFT]: 'yellow',
  [COE_STATES.DELETED]: 'yellow',
  [COE_STATES.DECLINED]: 'yellow',
  [COE_STATES.FULLY_ENROLLED]: 'yellow',
  [COE_STATES.NCEA_EMBARGO]: 'blue',
};

export class ChangeOfEnrolment {
  academicYear: ReferenceData;
  internalReference?: string;
  displayOrder?: number;
  priority?: number;
  state?: ReferenceData;
  isDirty?: boolean;
  tainted: boolean;
  studentProvidedExemptionReason: string;
  declarationAgreed: boolean;
  enrolledQualifications: EnrolledQualification[];
  willCompleteAward?: boolean;
  studyStart?: ReferenceData;
  studyStartOther?: string;
  postgradIntentions?: PostgradIntentions;
  studyLocation?: ReferenceData;
  paymentMethod?: ReferenceData;
  scholarshipProvider?: string;
  scholarshipName?: string;
  paymentMethodOther?: string;
  policeCheck?: PoliceCheck;
  backgroundChecks?: BackgroundCheck[];
  declarationBackgroundCheck?: BackgroundCheck[];
  cupStudyPlanAfter?: string;
  cupHowDidYouHearAbout?: ReferenceData;
  rationale?: string;
  experience?: string;
  otherQualification?: string;
  studyFullTime?: boolean;
  associatedPerson?: { [key: string]: AssociatedPerson };
  declarationCacAgree?: boolean;
  dateSubmitted?: string;
  conditions?: Condition[];
  teachingAssessment?: TeachingAssessment;
  refereeComments?: string;
  onlineAssessment?: OnlineAssessment;
  interviewAssessment?: InterviewAssessment;
  errors?: EnrolmentError[];

  constructor(obj) {
    Object.assign(this, obj);
  }

  private static addCoePrefix(code: string) {
    const prefix = 'coe_';
    if (code.startsWith(prefix)) {
      return code;
    }
    return prefix + code;
  }

  private static removeCoePrefix(code: string) {
    const prefix = 'coe_';
    if (code.startsWith(prefix)) {
      return code.slice(4);
    }
    return code;
  }

  public static deserialize(payload): ChangeOfEnrolment {
    const data = camelizeKeys(payload.change_of_enrolments || payload);
    data.cupHowDidYouHearAbout =
      typeof data.cupHowDidYouHearAbout === 'string'
        ? { code: data.cupHowDidYouHearAbout }
        : ReferenceData.deserialize(data.cupHowDidYouHearAbout);
    if (data.state?.code) {
      data.state.code = ChangeOfEnrolment.addCoePrefix(data.state.code);
    }
    data.enrolledQualifications = data.enrolledQualifications
      ? data.enrolledQualifications.map(EnrolledQualification.deserialize)
      : [];
    data.studyStart = ReferenceData.deserialize(data.studyStart);
    data.postgradIntentions = PostgradIntentions.deserialize(data.postgradIntentions);
    data.studyLocation = ReferenceData.deserialize(data.studyLocation);
    data.paymentMethod = ReferenceData.deserialize(data.paymentMethod);
    data.policeCheck = PoliceCheck.deserialize(data.policeCheck);
    data.backgroundChecks = BackgroundCheck.deserializeArray(data);
    data.associatedPerson = AssociatedPerson.deserializeAll(data.associatedPerson);
    data.declarationBackgroundCheck = data.declarationBackgroundCheck
      ? data.declarationBackgroundCheck.map((dbc) => BackgroundCheck.deserialize(dbc))
      : [];
    data.academicYear = { code: data.academicYear };
    data.conditions = data.conditions ? data.conditions.map(Condition.deserialize) : [];
    data.teachingAssessment = TeachingAssessment.deserialize(data.teachingAssessment);
    data.onlineAssessment = OnlineAssessment.deserialize(data.onlineAssessment);
    data.interviewAssessment = InterviewAssessment.deserialize(data.interviewAssessment);
    data.errors = data.errors ? data.errors.map((error) => EnrolmentError.deserialize(error)) : [];
    return new ChangeOfEnrolment(data);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public static serialize(instance): any {
    const clone = { ...instance };
    if (clone.state?.code) {
      clone.state.code = ChangeOfEnrolment.removeCoePrefix(clone.state.code);
    }
    clone.enrolledQualifications =
      (clone.enrolledQualifications && clone.enrolledQualifications.map(EnrolledQualification.serialize)) || [];

    if (clone.studyStart) {
      clone.studyStart = ReferenceData.serialize(clone.studyStart);
    }
    if (clone.postgradIntentions) {
      clone.postgradIntentions = PostgradIntentions.serialize(clone.postgradIntentions);
    }
    if (clone.studyLocation) {
      clone.studyLocation = ReferenceData.serialize(clone.studyLocation);
    }
    if (clone.paymentMethod) {
      clone.paymentMethod = ReferenceData.serialize(clone.paymentMethod);
    }
    if (clone.cupHowDidYouHearAbout) {
      clone.cupHowDidYouHearAbout = ReferenceData.serialize(clone.cupHowDidYouHearAbout);
    }

    if (clone.policeCheck) {
      clone.policeCheck = PoliceCheck.serialize(clone.policeCheck);
    }

    if (clone.associatedPerson) {
      clone.associatedPerson = AssociatedPerson.serializeAll(clone.associatedPerson);
    }

    if (clone.conditions) {
      clone.conditions = clone.conditions.map(Condition.serialize);
    }

    if (clone.teachingAssessment) {
      clone.teachingAssessment = TeachingAssessment.serialize(clone.teachingAssessment);
    }

    if (clone.onlineAssessment) {
      clone.onlineAssessment = OnlineAssessment.serialize(clone.onlineAssessment);
    }

    if (clone.interviewAssessment) {
      clone.interviewAssessment = InterviewAssessment.serialize(clone.interviewAssessment);
    }

    if (clone.declarationBackgroundCheck) {
      clone.declarationBackgroundCheck = clone.declarationBackgroundCheck
        ? clone.declarationBackgroundCheck.map((dbc) => BackgroundCheck.serialize(dbc))
        : [];
    }

    if (clone.errors) {
      clone.errors = clone.errors.map((error) => EnrolmentError.serialize(error));
    }

    clone.academicYear = clone.academicYear?.code;

    return { change_of_enrolments: snakeifyKeys(clone) };
  }

  public static serializeAllForStaff(instance): unknown {
    if (instance.associatedPerson) {
      instance.associatedPerson = AssociatedPerson.listifyAllForStaff(instance.associatedPerson);
    }

    const coeData = deepSnakeify(instance);

    if (coeData.interview_assessment) {
      coeData.interview_assessment = InterviewAssessment.serialize(coeData.interview_assessment);
    }

    return { change_of_enrolments: coeData };
  }
}

export class ChangeOfEnrolmentCourse {
  code: string;
  duration: number;
  startDate: string;
  endDate: string;

  constructor(obj) {
    Object.assign(this, obj);
  }

  public static deserialize(payload): ChangeOfEnrolmentCourse {
    const data = camelizeKeys(payload.courses || payload);
    if (data == null) {
      return null;
    }

    return new ChangeOfEnrolmentCourse(data);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public static serialize(instance): any {
    const clone = { ...instance };

    return { courses: snakeifyKeys(clone) };
  }
}

export class ChangeOfEnrolmentAction {
  changeAction: string;

  constructor(obj) {
    Object.assign(this, obj);
  }

  public static deserialize(payload): ChangeOfEnrolmentAction {
    const data = camelizeKeys(payload.courses || payload);
    if (data == null) {
      return null;
    }

    return new ChangeOfEnrolmentAction(data);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public static serialize(instance): any {
    const clone = { ...instance };
    return { courses: snakeifyKeys(clone) };
  }
}

export class ChangedEnrolmentCourse extends CourseOccurrence {
  changeAction: string;

  constructor(obj) {
    super(obj);
    Object.assign(this, obj);
  }
}

export class ChangedEnrolledQualification {
  enrolledQualification: EnrolledQualification;
  qualificationData: Qualification;
  courses: ChangedEnrolmentCourse[];

  constructor(obj) {
    Object.assign(this, obj);

    this.courses = obj.courses.map((c) => {
      const enrolledCourse = this.enrolledQualification.enrolledCourses.find(
        (ec) => ec.code === c.courseOccurrenceCode,
      );
      const changeAction = enrolledCourse && enrolledCourse.changeAction;
      if (enrolledCourse && changeAction) {
        c.changeAction = changeAction;
      }
      return c;
    });
  }

  get addedCourses() {
    const courses = this.enrolledQualification.enrolledCourses.filter((c) => c.changeAction === CHANGE_ACTIONS.ADDED);
    return courses.map((c) => {
      return this.courses.find((co) => co.courseOccurrenceCode === c.code);
    });
  }

  get droppedCourses() {
    const courses = this.enrolledQualification.enrolledCourses.filter((c) => c.changeAction === CHANGE_ACTIONS.DROPPED);
    return courses.map((c) => {
      return this.courses.find((co) => co.courseOccurrenceCode === c.code);
    });
  }

  get addedQualification() {
    return this.enrolledQualification.changeAction === CHANGE_ACTIONS.ADDED ? this.enrolledQualification : null;
  }

  get changedSubjectOptions(): EnrolledQualification {
    return this.enrolledQualification.changeAction === CHANGE_ACTIONS.CHANGED ? this.enrolledQualification : null;
  }

  get hasQualChanges() {
    return this.enrolledQualification.changeAction !== CHANGE_ACTIONS.NONE;
  }

  get hasCourses() {
    return this.enrolledQualification.enrolledCourses.some(
      (course) => course.changeAction === CHANGE_ACTIONS.ADDED || course.changeAction === CHANGE_ACTIONS.DROPPED,
    );
  }
}

export class FullEnrolmentChange {
  year: string;
  qualifications: ChangedEnrolledQualification[];
  courses: CourseOccurrence[];
  hasChanges: boolean;

  constructor(obj) {
    Object.assign(this, obj);
  }
}

export class ChangeOfEnrolmentUpdate {
  studentProvidedExemptionReason: string;
  declarationAgreed: boolean;

  constructor(obj) {
    Object.assign(this, obj);
  }

  public static serialize(instance): unknown {
    const clone = { ...snakeifyKeys(instance) };

    if (!clone.student_provided_exemption_reason) {
      clone.student_provided_exemption_reason = '';
    }

    if (!clone.declaration_agreed) {
      clone.declaration_agreed = false;
    }

    return { change_of_enrolments: clone };
  }
}

export class ChangeOfEnrolmentSummary {
  academicYear: string;
  state: ReferenceData;
  internalReference: string;

  constructor(obj) {
    Object.assign(this, obj);
  }

  public static deserialize(payload): ChangeOfEnrolmentSummary {
    const data = camelizeKeys(payload.change_of_enrolments || payload);
    if (data == null) {
      return null;
    }

    return new ChangeOfEnrolmentSummary(data);
  }

  public static deserializeAll(payload) {
    const data = payload.change_of_enrolments || payload;
    return data.map(ChangeOfEnrolmentSummary.deserialize);
  }
}
