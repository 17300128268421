.br-default .br-units {
  align-items: center;
}

.br-default .br-unit {
  margin-right: 5px;
  font-size: 35px;
  height: 30px;
  width: 30px;
}

.br-default .br-unit::after {
  content: "\2605";
  color: #d2d2d2;
}

.br-default .br-selected::after {
  color: #edb867;
}

.br-default .br-active::after {
  color: #edb867;
}
