import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'uc-error-message-display',
  templateUrl: './error-message-display.component.html',
  styleUrls: ['./error-message-display.component.scss'],
})
export class ErrorMessageDisplayComponent implements OnInit {
  @Input() errorMessage: string;
  @Input() testSelectorId: string;

  ngOnInit() {
    if (this.errorMessage) {
      this.errorMessage = this.errorMessage.trim();
    }
  }
}
