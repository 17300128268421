/* stylelint-disable value-keyword-case */

/* 2024-09-10 KY: Disabled above rule for this file. */

$fonts: (
  "primary": (
    "family": (
      "opensans",
      "Helvetica Neue",
      Helvetica,
      Arial,
      sans-serif,
    ),
    "weights": (
      "regular": 400,
      "semibold": 600,
      "bold": 700,
    ),
  ),
  "secondary": (
    "family": (
      "Georgia",
      serif,
    ),
    "weights": (
      "regular": 400,
      "bold": 700,
    ),
  ),
);
$primaryFontFamily: font("primary");
$primaryFontRegular: font("primary", "regular");
$primaryFontSemiBold: font("primary", "semibold");
$primaryFontBold: font("primary", "bold");
$secondaryFontFamily: font("secondary");
$secondaryFontFamilyRegular: font("secondary", "regular");
$secondaryFontFamilyBold: font("secondary", "bold");
