import { get } from 'lodash-es';
import { DateTime } from 'luxon';

import { APPLICATION_STATE, OFFER_DECISION_STATE } from '@constants/states.constants';
import { environment } from '@environment';
import { internalUrls } from '@shared/constants/internalUrls';
import { externalUrls } from '@shared/constants/urls.constants';
import { isUrlFunction } from '@shared/helpers/isUrlFunction';
import { camelizeKeys, deepSnakeify, snakeifyKeys } from '@shared/helpers/serialization';
import { AssociatedPerson } from '@shared/models/associated-person';
import { BackgroundCheck } from '@shared/models/background-check';
import { Contact } from '@shared/models/contact';
import { PostgradIntentions } from '@shared/models/postgrad-intentions';
import { ReferenceData } from '@shared/models/reference-data';

import { IncomingExchangeStudyAbroad } from './incoming-exchange-study-abroad';
import { InterviewAssessment } from './interview-assessment';
import { OnlineAssessment } from './online-assessment';
import { PoliceCheck } from './policeCheck';
import { TeachingAssessment } from './teaching-assessment';

export class Application {
  static STATE = APPLICATION_STATE;

  static STATE_COLOURS = {
    [Application.STATE.REVIEW]: 'yellow',
    [Application.STATE.PROGRESS]: 'yellow',
    [Application.STATE.INT_HOLD]: 'red',
    [Application.STATE.FULLY_ENROLLED]: 'blue',
    [Application.STATE.SUBMITTED]: 'green',
    [Application.STATE.REJECTED]: 'red',
    [Application.STATE.UNREADY]: 'yellow',
    [Application.STATE.NOT_SUBMITTED]: 'yellow',
    [Application.STATE.DRAFT]: 'yellow',
    [Application.STATE.BEING_ASSESSED]: 'blue',
    [Application.STATE.INCOMPLETE]: 'yellow',
    [Application.STATE.CONDITIONAL_OFFER]: 'orange',
    [Application.STATE.OFFER_OF_PLACE]: 'green',
    [Application.STATE.OFFER_ACCEPTED]: 'green',
    [Application.STATE.OFFER_DECLINED]: 'red',
    [Application.STATE.OFFER_DEFERRED]: 'blue',
    [Application.STATE.AGREEMENT_AVAILABLE]: 'green',
    [Application.STATE.AGREEMENT_ACCEPTED]: 'green',
    [Application.STATE.AGREEMENT_DECLINED]: 'red',
    [Application.STATE.FULLY_ENROLLED]: 'green',
    [Application.STATE.WITHDRAWN]: 'red',
    [Application.STATE.DEFERRAL_REQUESTED]: 'orange',
    [Application.STATE.NCEA_EMBARGO]: 'blue',
    [Application.STATE.OFFER_IN_PROGRESS]: 'yellow',
    [Application.STATE.OFFER_OF_PLACE_ACCEPT_DECLINE]: 'green',
    [OFFER_DECISION_STATE.DRAFT_DEFER]: 'orange',
  };

  static PROGRESS_STATES = [Application.STATE.PROGRESS, Application.STATE.UNREADY, Application.STATE.NOT_SUBMITTED];

  public academicYear: ReferenceData;
  public processName: ReferenceData;
  public state: ReferenceData;
  public admissionReason: ReferenceData;
  public fundingType: ReferenceData;
  public declarationTertiaryStudy: boolean;
  public otherQualification: string;
  public ibQualification: boolean;
  public cieQualification: boolean;
  public experience: string;
  public paymentMethod: ReferenceData;
  public scholarshipProvider: string;
  public scholarshipName: string;
  public paymentMethodOther: string;
  public careerKnowledge: string;
  public rationale: string;
  public rebateCategory: ReferenceData;
  public rebateCategoryOther: string;
  public declarationRebateCategoryDistance: boolean;
  public studyInNz: boolean;
  public studyFullTime: boolean;
  public studyStart: ReferenceData;
  public studyStartOther: string;
  public studyLocation: ReferenceData;
  public associatedPerson: { [key: string]: AssociatedPerson };
  public declarationBeenExcluded: boolean;
  public transferCredits: boolean;
  public declarationBackgroundCheck: BackgroundCheck[];
  public backgroundChecks: BackgroundCheck[];
  public declarationAgree: boolean;
  public declarationCacAgree: boolean;
  public declarationHasAgent: boolean;
  public agentName: string;
  public agentContactDetail: Contact;
  public agentAgency: string;
  public cieCenterNumber: string;
  public cieCandidateNumber: string;
  public ibResultsAvailable: boolean;
  public postgradIntentions: PostgradIntentions;
  public cupStudyPlanAfter: string;
  public cupHowDidYouHearAbout: ReferenceData;
  public insuranceProvider: ReferenceData;
  public agentApplyOnBehalf: boolean;
  public willCompleteAward: boolean;
  public incomingStudentExchangeStudyAbroadDetails: IncomingExchangeStudyAbroad;
  public npsRating: number;
  public declarationEmergencyContactInformationCorrect: boolean;
  public policeCheck: PoliceCheck;
  public alternateQualification: ReferenceData;
  public dateSubmitted: string;
  public teachingAssessment: TeachingAssessment;
  public refereeComments?: string;
  public onlineAssessment?: OnlineAssessment;
  public interviewAssessment?: InterviewAssessment;
  public creationSource: string;

  // Only used to detect if we should POST or PUT an application;
  public transient = false;

  constructor(applicationOptions) {
    Object.assign(this, applicationOptions);
  }

  // eslint-disable-next-line max-lines-per-function, complexity
  static deserialize(payload): Application {
    if (payload === null) {
      return null;
    }

    const application = camelizeKeys(payload.application);

    application.transient = !!payload.transient;
    application.academicYear = ReferenceData.deserialize(application.academicYear);
    application.processName = ReferenceData.deserialize(application.processName);
    application.state = ReferenceData.deserialize(application.state);
    application.admissionReason = ReferenceData.deserialize(application.admissionReason);
    application.fundingType = ReferenceData.deserialize(application.fundingType);
    application.rebateCategory = ReferenceData.deserialize(application.rebateCategory);

    application.paymentMethod = ReferenceData.deserialize(application.paymentMethod);
    application.studyStart = ReferenceData.deserialize(application.studyStart);
    application.studyLocation = ReferenceData.deserialize(application.studyLocation);
    application.cupHowDidYouHearAbout = ReferenceData.deserialize(application.cupHowDidYouHearAbout);
    application.associatedPerson = AssociatedPerson.deserializeAll(application.associatedPerson);
    application.declarationBackgroundCheck = application.declarationBackgroundCheck
      ? application.declarationBackgroundCheck.map((dbc) => BackgroundCheck.deserialize(dbc))
      : [];
    application.backgroundChecks = BackgroundCheck.deserializeArray(application);
    if (application.postgradIntentions) {
      application.postgradIntentions = PostgradIntentions.deserialize(application.postgradIntentions);
    } else {
      application.postgradIntentions = null;
    }
    application.agentContactDetail = Contact.deserialize(application.agentContactDetail);
    application.insuranceProvider = ReferenceData.deserialize(application.insuranceProvider);
    application.incomingStudentExchangeStudyAbroadDetails = IncomingExchangeStudyAbroad.deserialize(
      application.incomingStudentExchangeStudyAbroadDetails,
    );
    if (application.policeCheck) {
      application.policeCheck = PoliceCheck.deserialize(application.policeCheck);
    } else {
      application.policeCheck = null;
    }

    application.alternateQualification = ReferenceData.deserialize(application.alternateQualification);
    application.teachingAssessment = TeachingAssessment.deserialize(application.teachingAssessment);
    application.onlineAssessment = OnlineAssessment.deserialize(application.onlineAssessment);
    application.interviewAssessment = InterviewAssessment.deserialize(application.interviewAssessment);
    return new Application(application);
  }

  // eslint-disable-next-line max-lines-per-function, complexity
  static serialize(instance) {
    const clone = { ...instance };

    clone.academicYear = ReferenceData.serialize(clone.academicYear);
    clone.processName = ReferenceData.serialize(clone.processName);
    clone.state = ReferenceData.serialize(clone.state);
    clone.admissionReason = ReferenceData.serialize(clone.admissionReason);
    clone.rebateCategory = ReferenceData.serialize(clone.rebateCategory);
    clone.fundingType = ReferenceData.serialize(clone.fundingType);

    clone.paymentMethod = ReferenceData.serialize(clone.paymentMethod);
    clone.studyStart = ReferenceData.serialize(clone.studyStart);
    clone.studyLocation = ReferenceData.serialize(clone.studyLocation);
    clone.associatedPerson = AssociatedPerson.serializeAll(clone.associatedPerson);
    clone.cupHowDidYouHearAbout = ReferenceData.serialize(clone.cupHowDidYouHearAbout);
    clone.declarationBackgroundCheck = clone.declarationBackgroundCheck
      ? clone.declarationBackgroundCheck.map((dbc) => BackgroundCheck.serialize(dbc))
      : [];
    if (instance.postgradIntentions) {
      clone.postgradIntentions = PostgradIntentions.serialize(clone.postgradIntentions);
    } else {
      clone.postgradIntentions = null;
    }
    clone.agentContactDetail = Contact.serialize(clone.agentContactDetail);
    clone.insuranceProvider = ReferenceData.serialize(clone.insuranceProvider);
    clone.incomingStudentExchangeStudyAbroadDetails = IncomingExchangeStudyAbroad.serialize(
      clone.incomingStudentExchangeStudyAbroadDetails,
    );

    if (instance.policeCheck) {
      clone.policeCheck = PoliceCheck.serialize(clone.policeCheck);
    } else {
      clone.policeCheck = null;
    }

    clone.alternateQualification = ReferenceData.serialize(clone.alternateQualification);
    clone.teachingAssessment = TeachingAssessment.serialize(clone.teachingAssessment);
    clone.onlineAssessment = OnlineAssessment.serialize(clone.onlineAssessment);
    clone.interviewAssessment = InterviewAssessment.serialize(clone.interviewAssessment);
    return { application: snakeifyKeys(clone), transient: !!clone.transient };
  }

  static serializeApplicationStaff(application) {
    if (application.associatedPerson) {
      application.associatedPerson = AssociatedPerson.listifyAllForStaff(application.associatedPerson);
    }
    const applicationData = deepSnakeify(application);

    if (applicationData.interview_assessment) {
      applicationData.interview_assessment = InterviewAssessment.serialize(applicationData.interview_assessment);
    }
    return { application: applicationData };
  }
}

export class ApplicationAction {
  label: string;
  action: string;
  href?: string;
  academicYear?: string;

  constructor(options) {
    Object.assign(this, options);
  }

  get urlPath(): string {
    const url = get(externalUrls, this.href, internalUrls.dashboard.join('/'));

    if (this.href === 'UCSW.COEHOME' && url && this.academicYear && isUrlFunction(url)) {
      return `${url(this.academicYear)}`;
    } else {
      return url;
    }
  }

  static deserialize(payload, academicYear?: string): ApplicationAction {
    if (!payload) {
      return null;
    }

    const applicationAction = new ApplicationAction(camelizeKeys(payload));

    if (academicYear) {
      applicationAction.academicYear = academicYear;
    }

    return applicationAction;
  }
}

export class ApplicationSummary {
  academicYear: string;
  processName: string;
  state: string;
  primaryAction?: ApplicationAction;
  secondaryAction?: ApplicationAction[];

  constructor(options) {
    this.academicYear = null;
    this.processName = null;
    Object.assign(this, options);
  }

  // eslint-disable-next-line class-methods-use-this
  get currentDateAfterAcademicStart() {
    return DateTime.now() >= DateTime.fromFormat(environment.academicYearStart, 'd/MM');
  }

  // eslint-disable-next-line class-methods-use-this
  get currentYear() {
    return new Date().getFullYear();
  }

  get archived(): boolean {
    const moreThanOneYearAgo = this.currentYear - Number(this.academicYear) > 1;
    return moreThanOneYearAgo || (Number(this.academicYear) < this.currentYear && this.currentDateAfterAcademicStart);
  }

  static deserialize(payload): ApplicationSummary[] {
    if (payload === null) {
      return null;
    }

    return payload.application.map(ApplicationSummary.deserializeOne);
  }

  static deserializeOne(payload) {
    const summary = camelizeKeys(payload);

    summary.primaryAction = ApplicationAction.deserialize(summary.primaryAction, summary.academicYear);
    if (summary.secondaryAction) {
      summary.secondaryAction = summary.secondaryAction.map((action) =>
        ApplicationAction.deserialize(action, summary.academicYear),
      );
    } else {
      summary.secondaryAction = [];
    }

    summary.state =
      Application.PROGRESS_STATES.indexOf(summary.state) !== -1 ? Application.STATE.PROGRESS : summary.state;

    return new ApplicationSummary(summary);
  }
}
